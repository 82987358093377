import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const DieuKhoan = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="contactWrap">
      <div className="container">
        <h1 className="customHeading text-center">
          Điều khoản sử dụng proxy.luckywind.top
        </h1>
        Xin chào mừng quý khách đến trang-web của chúng tôi. Bằng việc sử dụng
        trang-web này, quý khách đã chấp thuận tuân thủ và chịu sự ràng buộc bởi
        các điều khoản sử dụng dưới đây. Xin quý khách xem những điều khoản dưới
        đây một cách cẩn trọng. Trong trường hợp quý khách không đồng ý với
        những điều khoản này, xin quý khách vui lòng không truy cập hay sử dụng
        trang-web này. Thuật ngữ “trang-web” nói đến tên miền của chúng tôi hoặc
        chúng tôi sẽ chỉ đích danh tên miền: “proxy.luckywind.top” và thuật ngữ
        “website” hay “website khác” nói về các địa chỉ tên miền khác.
        <div>
          <br />
          <strong>1. Chấp nhận thỏa thuận.</strong>
          <br />
          Quý khách đồng ý với các điều khoản và điều kiện được quy định tại
          thỏa thuận sử dụng này liên quan đến trang-web của chúng tôi. Thỏa
          thuận này cấu thành thỏa thuận toàn bộ và duy nhất giữa chúng tôi và
          quý khách. Thỏa thuận này có thể được chúng tôi sửa đổi vào bất kỳ
          thời điểm nào mà không cần phải có sự thông báo trước cho quý khách.
          Quý khách nên xem kỹ thỏa thuận này trước khi sử dụng.
        </div>
        <div>
          <br />
          <strong>2. Đăng thông tin.</strong>
          <br />
          Bất cứ thông tin hay dữ liệu nào bạn đăng tải trên trang-web phải là
          thông tin công khai (không phải thông tin mật) và sẽ thuộc quyền sở
          hữu của proxy.luckywind.top. Các thông tin này sẽ được chúng tôi sử
          dụng cho bất cứ mục đích nào.
        </div>
        <div>
          <br />
          <strong>3. Điều khoản sử dụng.</strong>
          <br />
          Mục đích sử dụng trang-web nào phải hợp pháp và không vi phạm các
          quyền hạn/ràng buộc của các bên thứ ba.
        </div>
        <div>
          <br />
          <strong>4. Những trang web/liên kết của các hãng thứ ba.</strong>
          <br />
          proxy.luckywind.top không chịu trách nhiệm đảm bảo tín sẵn sàng của
          bất cứ trang web hay tài liệu của các hãng thứ ba mà bạn truy xuất
          thông qua trang web này. Nếu bạn quyết định ghé thăm các trang được
          liên kết trong trang web này, bạn hoàn toàn chịu trách nhiệm bảo vệ
          máy tính khỏi vi rút hoặc các thành phần phá hoại khác.
          proxy.luckywind.top không bảo đảm tính xác thực cho bất cứ nội dung,
          quảng cáo, sản phẩm, dịch vụ hoặc các thông tin thanh toán có trên các
          trang web của các hãng thứ ba. proxy.luckywind.top không chịu trách
          nhiệm cho bất cứ tổn thất, mất mát hay những tổn thương gây ra, hoặc
          có liên hệ mật thiết với, bởi bất cứ nội dung, quảng cáo, sản phẩm,
          dịch vụ hoặc thông tin có trên các trang web của các hãng thứ ba. Các
          liên kết đến trang web này phải là liên kết trực tiếp đến toàn bộ
          trang và không được nhúng vào một phần của trang web khác.
          proxy.luckywind.top không thừa nhận bất cứ trách nhiệm pháp lý nào gây
          ra bởi các liên kết từ các trang web khác đến trang web này.
        </div>
        <div>
          <br />
          <strong>5. Những phủ nhận và giới hạn pháp lý.</strong>
          <br />
          Thông tin và các tài liệu khác trên trang web có chứa những sai sót về
          lỗi chính tả, proxy.luckywind.top không đảm bảo sự chính xác hay sự
          hoàn thiện của thông tin và các tài liệu cũng như sự tin cậy của bất
          cứ phát biểu hay những thông tin khác được đăng tải thông qua trang
          web này. Bạn hoàn toàn chịu trách nhiệm về việc tin tưởng vào các phát
          biểu hay thông tin có trên trang web này. proxy.luckywind.top có quyền
          chỉnh sửa các lỗi hay bỏ bớt bất cứ phần nào của trang web cũng như
          các tài liệu, sản phẩm, dịch vụ hoặc giá cả tại bất cứ lúc nào mà
          không cần báo trước. trang-web không đảm bảo rằng các chức năng có
          trong trang web này không bị đứt quãng hay không có lỗi. trang-web có
          thể kết thúc, thay đổi, đình chỉ bất cứ phần nào của trang web này tại
          bất cứ lúc nào mà không cần báo trước.
        </div>
        <div>
          <br />
          <strong>6. Phần mềm.</strong>
          <br />
          Một số phần mềm nhất định được sử dụng để bảo vệ thành quả của
          proxy.luckywind.top tùy thuộc vào thời gian có thể được tải xuống từ
          trang web này. Bạn được cấp quyền để sử dụng phần mềm đó cho mục đích
          mà nó được đặc tả. Bạn không được sử dụng phần mềm cho bất kỳ mục địch
          nào khác và không được phân phối lại, bán, dịch ngược, lần ngược, tháo
          rời với phần mềm đó.
        </div>
        <div>
          <br />
          <strong>
            7. Quy định về đăng ký, sử dụng tài khoản và thanh toán phí dịch vụ.
          </strong>
          <br />
          Chúng tôi cung cấp các Dịch vụ cho Bạn hoàn toàn thông qua hệ thống
          trực tuyến của Website proxy.luckywind.top từ khi Bạn bắt đầu đăng ký
          tài khoản, lựa chọn dịch vụ cũng như tiến hành thanh toán phí dịch vụ
          mà Bạn sử dụng Dịch vụ của Chúng tôi. <br />
          Bạn xác nhận và đồng ý rằng Bạn đã nghiên cứu kỹ càng và sẽ tuân thủ
          đầy đủ các quy định về đăng ký tài khoản, phương thức tính phí và
          thanh toán chi phí được đăng trên Website proxy.luckywind.top của
          chúng tôi (“Quy định về Đăng ký và Sử dụng Dịch vụ”). Bằng việc Bạn
          xác nhận hoàn thành thủ tục đăng ký tài khoản trên website
          proxy.luckywind.top, Bạn đã chấp nhận bị ràng buộc thực hiện đối với
          các Quy định về Đăng ký và Sử dụng Dịch vụ như vậy khi Bạn sử dụng các
          Dịch vụ. <br />
          Chúng tôi giữ quyền nhưng không có nghĩa vụ thông báo tới người sử
          dụng đối với bất kỳ sự thay đổi và/hoặc sửa đổi các Quy định về Đăng
          ký và Sử dụng Dịch vụ. Những sự thay đổi và/hoặc sửa đổi như vậy sẽ có
          hiệu lực ngay khi đăng lên Website proxy.luckywind.top. Nếu Bạn tiếp
          tục sử dụng các Dịch vụ sau khi các thay đổi và/hoặc sửa đổi được đăng
          lên, Bạn đã chấp nhận và đồng ý với các thay đổi và/hoặc sửa đổi đó.
          Chúng tôi khuyến nghị Bạn thường xuyên kiểm tra trên Website
          proxy.luckywind.top và liên hệ với nhân viên hỗ trợ của Chúng tôi để
          có được bản cập nhật các Quy định về Đăng ký và Sử dụng Dịch vụ mới
          nhất.
        </div>
        <div>
          <br />
          <strong>8. Luật và quyền hạn.</strong>
          <br />
          Các tranh chấp phát sinh sẽ được độc quyền giải quyết theo thẩm quyền
          của tòa án của Việt Nam. Bất kỳ nguyên nhân của hành động mà bạn có
          thể có liên quan đến việc sử dụng trang web này phải được bắt đầu
          trong vòng một (1) năm sau khi yêu cầu hoặc nguyên nhân gây ra hành
          động phát sanh. Nếu bất kỳ điều nào trong các điều khoản và điều kiện
          được xác định là bất hợp pháp, không hợp lệ hoặc không thể thi hành
          được do luật pháp của quốc gia mà trong đó các điều khoản và điều kiện
          có thể dùng để có hiệu quả, sau đó đến mức độ và trong phạm vi thẩm
          quyền giải quyết, trong đó Điều kiện mà Quy hay là bất hợp pháp, không
          hợp lệ hoặc không thể thi hành được, nó sẽ được tách ra và xóa khỏi
          điều khoản đó và các điều khoản và điều kiện còn lại sẽ tiếp tục được
          ràng buộc và thực thi. Chúng tôi cung cấp dịch vụ và người sử dụng cần
          sử dụng cần tuân thủ theo luật pháp ở nơi sử dụng dịch vụ. Nếu người
          sử dụng dịch vụ proxy.luckywind.top trái với luật pháp nơi sử dụng thì
          người sử dụng phải hoàn toàn chịu trách nhiệm trước pháp luật.
        </div>
      </div>
    </div>
  );
};
export default DieuKhoan;
