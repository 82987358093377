const ourTeams = [
  {
    name: 'Dream Boy',
    title: 'CEO',
    image: '/images/about/avt.jpg',
    fb: '#',
    tw: '#',
    lk: '#'
  },
  {
    name: 'Kakao talk',
    title: 'CTO',
    image: '/images/about/avt.jpg',
    fb: '#',
    tw: '#',
    lk: '#'
  },
  {
    name: 'Paul Jones',
    title: 'Design Lead',
    image: '/images/about/avt.jpg',
    fb: '#',
    tw: '#',
    lk: '#'
  },
  {
    name: 'Sara Hardin',
    title: 'Project Manager',
    image: '/images/about/avt.jpg',
    fb: '#',
    tw: '#',
    lk: '#'
  }
]
const OurTeam = () => {
  return (
    <div className='ourTeamWrap'>
      <div className='container'>
        <div className='text-center'>
          <h2>Đội ngũ sáng lập của chúng tôi</h2>
        </div>
        <div className='ourTeams'>
          {ourTeams?.map(item => (
            <div className='ourTeam'>
              <div className='memberImg'>
                <img src={item.image} alt='avt' />
                <div className='mSocial'>
                  <ul>
                    <li><a href={item.fb} target='_blank'><img src='/images/about/fb.svg' alt='fb' /></a></li>
                    <li><a href={item.fb} target='_blank'><img src='/images/about/tw.svg' alt='tw' /></a></li>
                    <li><a href={item.fb} target='_blank'><img src='/images/about/ln.svg' alt='ln' /></a></li>
                  </ul>
                </div>
              </div>
              <h3>{item.name}</h3>
              <p>{item.title}</p>
            </div>
          ))}
        </div>
      </div>
    </div >
  )
}

export default OurTeam
