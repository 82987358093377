import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 99999,
        color: '#000',
        background: 'rgba(0,0,0,.3)'
    },
}));

export default function FullLoading({ open }) {
    const classes = useStyles();

    return (
        <div>
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="primary" />
            </Backdrop>
        </div>
    );
}