import React from "react";
import CustomLink from "../../commons/Link/Link";

const listItem = [
  {
    title: "CHI PHÍ CỰC THẤP",
    desc:
      "Với số tiền chỉ cần mấy chục nghìn bạn có thể được trải nghiệm proxy 4g",
  },
  {
    title: "THAO TÁC NHANH NGỌN",
    desc:
      "Với những ai muốn có proxy 4G các bạn phải mua sim, điện thoại và những phần mềm cần thiết khác để sử dụng. Với chúng tôi bạn chỉ cần đặt trong vài bước đơn giản",
  },
  {
    title: "HỆ THỐNG CHẤT LƯỢNG",
    desc:
      "Chúng tôi xây dựng hạ tầng với 1 lượng ip phủ các tỉnh thành, đáp ứng chuyển ip nhanh chóng",
  },
  {
    title: "BĂNG THÔNG VÔ HẠN",
    desc: "Băng thông sử dụng không giới hạn",
  },
];
const HomePartner = () => {
  return (
    <div className="partnerHomeWrap">
      <div className="container">
        <div className="partnerHome">
          <div className="homePartnerLeft">
            <h3 className="heading">Khi bạn sử dụng dịch vụ của chúng tôi</h3>
            <p>
              Hãy nhanh tay đăng ký sử dụng dịch vụ của chúng tôi để nhận được
              các ưu đãi hấp dẫn
            </p>
            {/* <CustomLink
              url=""
              text="Xem thông tin chi tiết"
              icon
              className={"red"}
            /> */}
          </div>
          <div className="homePartnerRight">
            {listItem?.map((item, i) => (
              <div key={i} className="rewardItem">
                <div className="pNumber">
                  <span>{`0${i + 1}`}</span>
                  <img src="/images/commons/pointer.svg" alt="icon" />
                </div>
                <h4 className="title">{item.title}</h4>
                <p className="desc">{item.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePartner;
