const Coop = () => {
  return (
    <div className='coopWrap'>
      <div className='container'>
        <div className='text-center'>
          <h2 className='customHeading'>Hợp tác với chúng tôi  như thế nào</h2>
        </div>
        <div className='coopContainer'>
          <div className='coopItem'>
            <h3>Tìm hiểu</h3>
            <p>Chủ đầu tư tìm hiểu thông tin đầu tư, bảng giá.</p>
          </div>
          <div className='coopItem'>
            <h3>Tư vấn</h3>
            <p>Đại diện Công ty tư vấn chi phí và điều kiện đầu tư.</p>
          </div>
          <div className='coopItem'>
            <h3>Ký kết hợp đồng</h3>
            <p>Ký kết hợp đồng hợp tác nhượng quyền</p>
          </div>
          <div className='coopItem'>
            <h3>Setup và vận hành</h3>
            <p>Chủ đầu tư set up, hoàn thiện và vận hành hệ thống.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Coop
