import { FormControlLabel, TextField, Checkbox } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  useUserDispatch,
  loginUser,
  registerUser,
} from "../../../context/UserContext";
import useStyles from "./styles";

const RegisterPage = ({ props }) => {
  const classes = useStyles();
  const history = useHistory();

  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [showPassword, setShowPassword] = useState("");
  const [dieuKhoan, setDieuKhoan] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div className="loginContainer">
      <div className="container">
        <div className="loginWrap">
          <div className="loginLeft">
            <img src="/images/commons/login.svg" alt="login" />
          </div>
          <div className="loginRight">
            <h2>Đăng ký</h2>
            <TextField
              id="email"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <img src="/images/commons/email.svg" alt="" />
                  </InputAdornment>
                ),
              }}
              value={loginValue}
              onChange={(e) => setLoginValue(e.target.value)}
              margin="normal"
              placeholder="Nhập địa chỉ email của bạn"
              type="email"
              label="Email"
              fullWidth
              className="customInput"
            />
            <TextField
              id="password"
              label="Mật khẩu"
              className="customInput"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <img src="/images/commons/lock.svg" alt="" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={passwordValue}
              onChange={(e) => setPasswordValue(e.target.value)}
              margin="normal"
              placeholder="Nhập mật khẩu của bạn"
              type={showPassword ? "text" : "password"}
              fullWidth
            />

            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dieuKhoan}
                    onChange={() => {
                      setDieuKhoan(!dieuKhoan);
                    }}
                  />
                }
              />
              <>
                Tôi đồng ý chấp nhận{" "}
                <strong>
                  <a href="/dieu-khoan">điều khoản</a>
                </strong>{" "}
                dịch vụ
              </>
            </div>
            <button
              className="button red"
              disabled={
                loginValue.length === 0 ||
                passwordValue.length === 0 ||
                !dieuKhoan
              }
              onClick={() =>
                registerUser(
                  userDispatch,
                  loginValue,
                  passwordValue,
                  history,
                  setIsLoading,
                  setError,
                )
              }
            >
              Đăng ký
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RegisterPage;
