import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Button,
  Box,
  MenuItem,
  Typography,
  IconButton,
} from "@material-ui/core";

import { Delete, Refresh } from "@material-ui/icons";

import { makeStyles } from "@material-ui/styles";
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";

// data
// import Filter from "./Filter";
import DataTable from "../../components/Table/Table";
import {
  proxyVietList,
  proxyVietOrders,
  proxyVietChangeIps,
  proxyVietDelete,
} from "../../apis/proxyViet";
import {
  convertTimestampToDateTime,
  getNowUnix,
  secondsToDhms,
} from "../../helpers/utils";
import DialogCreate from "./DialogCreate";
import DialogChangeIpDuration from "./DialogChangeDuration";
import DialogChangeUserPass from "./DialogChangeUserPass";

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
}));
const defaultParams = {
  page: 1,
  limit: 100,
  search: "",
};

export default function ProxyViet() {
  const classes = useStyles();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [fullLoading, setFullLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  // const mounted = React.useRef(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openChangeDuration, setOpenChangeDuration] = useState(false);
  const [openChangeUserPass, setOpenChangeUserPass] = useState(false);

  const [filter, setFilter] = useState(defaultParams);
  const [total, setTotal] = useState(0);
  const location = useLocation();
  const history = useHistory();

  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = queryString.parse(location.search);
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history],
  );

  async function listData() {
    const list = await proxyVietList(filter);
    setOrders(list?.data);
    setTotal(list?.total);
  }
  useEffect(() => {
    listData();

    const timer1 = setInterval(async () => {
      await listData();
    }, 5000);

    return () => {
      clearInterval(timer1);
    };
  }, [filter]);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  const handleCancel = async () => {
    // setFullLoading(true);
    // await orderCancel(selectedRow);
    await listData();
    // setFullLoading(false);
    setOpenConfirm(false);
  };

  const handleCreate = async (data) => {
    var createResp = await proxyVietOrders(data);
    console.log(createResp);

    // await listData();
    // setOpenAddDialog(false);
  };

  const handleColor = (status) => {
    if (status === "RUNNING" || status === "CONNECTED") {
      return "#33CC66";
    }

    if (
      status === "CANCELED" ||
      status === "DISCONNECT" ||
      status === "LIMITED"
    ) {
      return "#FF6666";
    }
    if (status === "DONT_USE") {
      return "#BBBBBB";
    }

    if (status === "CHANGE_IP" || status === "START") {
      return "#FFCC33";
    }
    return "white";
    // return status == "LIVE" ? 'green' : status == "DEAD" ? 'red' : 'grey'
  };

  const columns = [
    {
      field: "stt",
      headerName: "STT",
      flex: 0.1,
      editable: false,
    },
    {
      field: "usage_status",
      headerName: "Trạng thái",
      flex: 0.1,
      editable: false,
      renderCell: (params) => {
        return (
          <div
            style={{
              lineHeight: "normal",
            }}
          >
            <div
              style={{
                lineHeight: "normal !important",
                backgroundColor: handleColor(params?.row?.usage_status),
              }}
            >
              {params?.row?.usage_status}
            </div>
            <div
              style={{
                lineHeight: "normal !important",
                backgroundColor: handleColor(params?.row?.proxy_status),
              }}
            >
              {params?.row?.proxy_status}
            </div>
          </div>
        );
      },
    },
    {
      field: "proxy",
      headerName: "Thời gian",
      flex: 0.3,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: "normal" }}>
            <div style={{ lineHeight: "normal !important" }}>
              {params?.row?.uid}{" "}
              <strong>
                {getNowUnix() - params?.row?.created_at > 120
                  ? ""
                  : " - ( NEW )"}
              </strong>
            </div>

            <div style={{ lineHeight: "normal !important" }}>
              Tạo lúc:
              <strong>{`${convertTimestampToDateTime(
                params?.row?.created_at,
              )}`}</strong>
              <strong>
                {getNowUnix() - params?.row?.created_at > 120
                  ? ""
                  : " - ( NEW )"}
              </strong>
            </div>
            <div style={{ lineHeight: "normal !important" }}>
              thời gian đổi ip:
              <strong>{` ${secondsToDhms(
                params?.row?.change_ip_duration,
              )}`}</strong>
              {` -`}
              {`${
                params?.row?.first_connected_at - params?.row?.change_ip_at > 0
                  ? secondsToDhms(
                      params?.row?.first_connected_at -
                        params?.row?.change_ip_at,
                    )
                  : secondsToDhms(getNowUnix() - params?.row?.change_ip_at)
              }`}
              {` -  `}
              {`${secondsToDhms(
                params?.row?.last_connected_at - params?.row?.started_at,
              )}`}
            </div>
            <div style={{ lineHeight: "normal !important" }}>
              Hạn sử dụng:{" "}
              <strong>{convertTimestampToDateTime(params?.row?.due_at)}</strong>
            </div>
          </div>
        );
      },
    },
    {
      field: "public",
      headerName: "thông tin proxy",
      flex: 0.7,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: "normal" }}>
            <div style={{ lineHeight: "normal !important" }}>
              {params?.row?.ssh_info}
            </div>
            <div
              style={{
                lineHeight: "normal !important",
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              proxy:{" "}
              <strong>{`http://${params.row.proxy_user}:${params.row.proxy_pass}@${params.row.proxy_ip}:${params.row.proxy_port}`}</strong>
            </div>
            <div style={{ lineHeight: "normal !important" }}>
              ip: <strong>{params?.row?.ip}</strong>
            </div>
            <div style={{ lineHeight: "normal !important" }}>
              url đổi ip: <strong>{params?.row?.link_change}</strong>
            </div>
          </div>
        );
      },
    },
    {
      field: "number",
      headerName: "number",
      type: "number",
      flex: 0.1,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton>
              {params?.row?.usage_status === "CANCELED" ? (
                <Delete
                  onClick={async () => {
                    await proxyVietDelete([params?.row?.uid]);
                    await listData();
                  }}
                />
              ) : (
                <Refresh
                  onClick={async () => {
                    await proxyVietChangeIps([params?.row?.uid]);
                    await listData();
                  }}
                />
              )}
            </IconButton>
          </>
        );
      },
    },
  ];

  var menus = [];
  menus.push({
    name: "Change UserPass",
    cp: (
      <MenuItem
        onClick={async () => {
          setOpenChangeUserPass(true);
        }}
      >
        <Typography>Cập nhật user/pass</Typography>{" "}
      </MenuItem>
    ),
  });
  menus.push({
    name: "Change IP",
    cp: (
      <MenuItem
        onClick={async () => {
          await proxyVietChangeIps({ uids: selectedRow });
        }}
      >
        <Typography>Đổi IP</Typography>{" "}
      </MenuItem>
    ),
  });

  menus.push({
    name: "UpdateDuration",
    cp: (
      <MenuItem
        onClick={() => {
          setOpenChangeDuration(true);
        }}
      >
        <Typography>Cập nhật thời gian đổi ip</Typography>
      </MenuItem>
    ),
  });

  return (
    <>
      {/* <FullLoading open={fullLoading} /> */}
      <PageTitle title="Proxy Việt" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignContent="center"
            alignItems="center"
          >
            <Button
              onClick={() => {
                setOpenAddDialog(true);
              }}
              style={{ height: 48, width: 120, marginRight: 10 }}
              variant="contained"
              color="primary"
            >
              Đặt proxy
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Widget
            title="View Orders"
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
            menus={menus}
          >
            <DataTable
              checkboxSelection={true}
              rowHeight={90}
              columns={columns}
              data={orders?.map((item, i) => ({
                ...item,
                id: item.uid,
                stt: i + 1 + (filter?.page - 1) * filter?.limit,
              }))}
              loading={loading}
              filter={filter}
              setFilter={setFilterToParams}
              setSelectedRow={setSelectedRow}
              total={total}
            />
          </Widget>
        </Grid>
      </Grid>

      {openAddDialog && (
        <DialogCreate
          open={openAddDialog}
          onClose={() => {
            setOpenAddDialog(false);
          }}
          aria-labelledby="draggable-dialog-title"
          fullWidth
          maxWidth="lg"
          onSubmitForm={async (data) => {
            // console.log(data);
            await handleCreate(data);
            // await listData();
          }}
        />
      )}

      {openChangeUserPass && (
        <DialogChangeUserPass
          open={openChangeUserPass}
          onClose={() => {
            setOpenChangeUserPass(false);
          }}
          uids={selectedRow}
        />
      )}
      {openChangeDuration && (
        <DialogChangeIpDuration
          open={openChangeDuration}
          onClose={() => {
            setOpenChangeDuration(false);
          }}
          uids={selectedRow}
        />
      )}
    </>
  );
}
