import { makeStyles } from "@material-ui/styles";
import React from "react";
// components
import PageTitle from "../../components/PageTitle/PageTitle";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export default function Contact() {
  return (
    <div style={{ background: "#fff" }}>
      <PageTitle color="#000" title="Hỗ trợ liên hệ" />
      <div className="wrap-i" style={{ display: "flex" }}>
        <div className="ct-heading">Liên hệ</div>
        <div className="ct-hotline">
          <div className="ct-info">
            <div className="ct-left">Telegram</div>
            <div className="ct-right">@kapuchino_1234</div>
          </div>
        </div>
      </div>
      {/* <div className="ct-form">
        <form>
          <div className="ct-row">
            <div className="ct-label">Yêu cầu</div>
            <div className="ct-input">
              <Select
                value={contact.type}
                onChange={handleChange}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{ "aria-label": "Without label" }}
              >
                {types?.map((pk) => (
                  <MenuItem key={pk.name} value={pk.value}>
                    {pk.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <div className="ct-row">
            <div className="ct-label">Tiêu đề</div>
            <div className="ct-input">
              <TextField
                value={contact.title}
                onChange={(event) =>
                  setContact({ ...contact, title: event.target.value })
                }
              />
            </div>
          </div>
          <div className="ct-row">
            <div className="ct-label">Nội dung</div>
            <div className="ct-input">
              <TextareaAutosize
                value={contact.message}
                onChange={(event) =>
                  setContact({ ...contact, message: event.target.value })
                }
              />
            </div>
          </div>
          <div className="ct-row">
            <div className="ct-label"></div>
            <div className="ct-input">
              <Button
                disabled={!contact.title || !contact.type}
                type="submit"
                variant="contained"
                color="primary"
              >
                Gửi
              </Button>
            </div>
          </div>
        </form>
      </div> */}
    </div>
  );
}
