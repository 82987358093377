import { FormControlLabel, TextField } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Checkbox from "@material-ui/core/Checkbox";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useUserDispatch, loginUser } from "../../../context/UserContext";
import useStyles from "./styles";

const LoginPage = ({ props }) => {
  const classes = useStyles();
  const history = useHistory();

  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [showPassword, setShowPassword] = useState("");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div className="loginContainer">
      <div className="container">
        <div className="loginWrap">
          <div className="loginLeft">
            <img src="/images/commons/login.svg" alt="login" />
          </div>
          <div className="loginRight">
            <h2>Đăng nhập</h2>
            <p>Nếu bạn chưa có tài khoản bạn</p>
            <p style={{ marginBottom: 30 }}>
              Bạn liên hệ{" "}
              <strong>
                <Link to="/dang-ky">Đăng ký ở đây ?</Link>
              </strong>
            </p>
            <TextField
              id="email"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <img src="/images/commons/email.svg" alt="" />
                  </InputAdornment>
                ),
              }}
              value={loginValue}
              onChange={(e) => setLoginValue(e.target.value)}
              margin="normal"
              placeholder="Nhập địa chỉ email của bạn"
              type="email"
              label="Email"
              fullWidth
              className="customInput"
            />
            <TextField
              id="password"
              label="Mật khẩu"
              className="customInput"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <img src="/images/commons/lock.svg" alt="" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={passwordValue}
              onChange={(e) => setPasswordValue(e.target.value)}
              margin="normal"
              placeholder="Nhập mật khẩu của bạn"
              type={showPassword ? "text" : "password"}
              fullWidth
            />
            <div className="afterLoginForm">
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Lưu mật khẩu"
              />
              <Link to="/quen-mat-khau">Quên mật khẩu?</Link>
            </div>
            <button
              className="button red"
              disabled={loginValue.length === 0 || passwordValue.length === 0}
              onClick={() =>
                loginUser(
                  userDispatch,
                  loginValue,
                  passwordValue,
                  history,
                  setIsLoading,
                  setError,
                )
              }
            >
              Đăng nhập
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginPage;
