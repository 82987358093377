import React from "react";
import { loginFunc, registerFunc } from "../apis/auth";

var jwt = require("jsonwebtoken");

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "NAP_TIEN":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export {
  UserProvider,
  useUserState,
  useUserDispatch,
  loginUser,
  signOut,
  registerUser,
  userNapTien,
};

// ###########################################################

async function registerUser(
  dispatch,
  email,
  password,
  history,
  setIsLoading,
  setError,
) {
  setError(false);
  setIsLoading(true);
  if (!!email && !!password) {
    await registerFunc({
      email: email,
      password,
    })
      .then((res) => {
        console.log(res);
        setError(null);
        setIsLoading(false);
        history.push("/dang-ky-thanh-cong");
      })
      .catch((err) => {
        console.log("err", err);
        // dispatch({ type: "LOGIN_FAILURE" });
        setError(true);
        setIsLoading(false);
      });
  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }
}
async function loginUser(
  dispatch,
  email,
  password,
  history,
  setIsLoading,
  setError,
) {
  setError(false);
  setIsLoading(true);
  if (!!email && !!password) {
    await loginFunc({
      email: email,
      password,
    })
      .then((res) => {
        // console.log(res);
        var user = jwt.decode(res.token);
        localStorage.setItem("token", res.token);
        localStorage.setItem("user_name", user?.user_name);
        setError(null);
        setIsLoading(false);
        dispatch({ type: "LOGIN_SUCCESS" });
        history.push("/app/proxy-viet");
      })
      .catch((err) => {
        console.log("err", err);
        // dispatch({ type: "LOGIN_FAILURE" });
        setError(true);
        setIsLoading(false);
      });
  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem("token");
  localStorage.removeItem("roles");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/dang-nhap");
}

function userNapTien(dispatch, history) {
  dispatch({ type: "NAP_TIEN" });
  history.push("/app/deposit");
}
