import { useHistory } from "react-router-dom";

const Price = () => {
  const history = useHistory();
  const gotoContact = (e) => {
    e.preventDefault();
    history.push("/lien-he");
  };
  return (
    <div className="priceWrap">
      <div className="container priceContainer">
        <div className="priceItem">
          <div className="priceNumber">1 Day (-0%)</div>
          <h3>16.000đ/day</h3>
          <p>Thử nghiệm đánh giá hiệu quả</p>
          <ul>
            <li>1 cổng</li>
            <li>Thời gian đổi ip 10s~30s</li>
            <li>Hỗ trợ 24/7</li>
            <li>Băng thông không giới hạn</li>
            {/* <li className="inactive">Không hỗ trợ kênh youtube riêng</li>
            <li className="inactive">Không hỗ trợ dashboard riêng.</li> */}
          </ul>
          <a
            className="button black"
            // onClick={(e) => gotoContact(e)}
            href="/dang-ky"
          >
            Đăng ký
          </a>
        </div>{" "}
        {/* End Item */}
        <div className="priceItem" style={{ background: "#FFE4E4" }}>
          <div className="priceNumber">3 Days (-6%)</div>
          <h3>45.000đ/ 3 days</h3>
          <p>Tiết kiệm 6%</p>
          <ul>
            <li>1 cổng</li>
            <li>Thời gian đổi ip 10s~30s</li>
            <li>Hỗ trợ 24/7</li>
            <li>Băng thông không giới hạn</li>
          </ul>
          <a
            className="button black"
            // onClick={(e) => gotoContact(e)}
            href="/dang-ky"
          >
            Đăng ký
          </a>
        </div>{" "}
        {/* End Item */}
        {/* End Item */}
        <div className="priceItem" style={{ background: "#FFE4E4" }}>
          <div className="priceNumber">7 Days (-19%)</div>
          <h3>90.000đ / 7 days</h3>
          <p>Tiết kiệm 19%</p>
          <ul>
            <li>1 cổng</li>
            <li>Thời gian đổi ip 10s~30s</li>
            <li>Hỗ trợ 24/7</li>
            <li>Băng thông không giới hạn</li>
          </ul>
          <a
            className="button black"
            // onClick={(e) => gotoContact(e)}
            href="/dang-ky"
          >
            Đăng ký
          </a>
        </div>{" "}
        <div className="priceItem pro" style={{ background: "#C91212" }}>
          <div className="priceNumber">14 Days (-24%)</div>
          <h3>170.000đ/ 14 days</h3>
          <p>Tiết kiệm 24%</p>
          <ul>
            <li>1 cổng</li>
            <li>Thời gian đổi ip 10s~30s</li>
            <li>Hỗ trợ 24/7</li>
            <li>Băng thông không giới hạn</li>
          </ul>
          <div className="lastIn"></div>
          <a
            className="button black"
            // onClick={(e) => gotoContact(e)}
            href="/dang-ky"
          >
            Đăng ký
          </a>
        </div>
        <div className="priceItem" style={{ background: "#FFE4E4" }}>
          <div className="priceNumber">30 Days (-27%)</div>
          <h3>350.000đ / 30 days</h3>
          <p>Tiết kiệm 27%</p>
          <ul>
            <li>1 cổng</li>
            <li>Thời gian đổi ip 10s~30s</li>
            <li>Hỗ trợ 24/7</li>
            <li>Băng thông không giới hạn</li>
            <li>Deal giá với số lượng lớn</li>
          </ul>
          <a
            className="button black"
            // onClick={(e) => gotoContact(e)}
            href="/dang-ky"
          >
            Đăng ký
          </a>
        </div>{" "}
      </div>
    </div>
  );
};

export default Price;
