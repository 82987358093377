import About from "./pages/about";
import PricesPage from "./pages/bang-gia";
import CSPage from "./pages/co-so";
import RegisterPage from "./pages/dang-ky";
import LoginPage from "./pages/dang-nhap";
import ServicePage from "./pages/dich-vu";
import ServicePage1 from "./pages/dich-vu/ban-video-doc-la";
import ServicePage2 from "./pages/dich-vu/tang-view-video-youtube";
import ServicePage3 from "./pages/dich-vu/tang-view-website";
import HomePage from "./pages/home";
import ContactPage from "./pages/lien-he";
import DangKyThanhCongPage from "./pages/dang-ky-thanh-cong";
import ForgotPassword from "./pages/quen-mat-khau";
import ProcessPage from "./pages/quy-trinh";
import DieuKhoan from "./pages/dieu-khoan";

const routes = [
  { path: "/", exact: true, name: "Home", ContentComponent: HomePage },
  { path: "/gioi-thieu", exact: true, name: "About", ContentComponent: About },
  { path: "/co-so", exact: true, name: "About", ContentComponent: CSPage },
  {
    path: "/bang-gia",
    exact: true,
    name: "Prices",
    ContentComponent: PricesPage,
  },
  {
    path: "/quy-trinh",
    exact: true,
    name: "Process",
    ContentComponent: ProcessPage,
  },
  {
    path: "/dich-vu",
    exact: true,
    name: "Service",
    ContentComponent: ServicePage,
  },
  {
    path: "/dich-vu/ban-video-doc-la",
    exact: true,
    name: "Service1",
    ContentComponent: ServicePage1,
  },
  {
    path: "/dich-vu/tang-view-video-youtube",
    exact: true,
    name: "Service2",
    ContentComponent: ServicePage2,
  },
  {
    path: "/dich-vu/tang-view-website",
    exact: true,
    name: "Service3",
    ContentComponent: ServicePage3,
  },
  {
    path: "/dang-nhap",
    exact: true,
    name: "Login",
    ContentComponent: LoginPage,
  },
  {
    path: "/dang-ky",
    exact: true,
    name: "Register",
    ContentComponent: RegisterPage,
  },
  {
    path: "/dang-ky-thanh-cong",
    exact: true,
    name: "Register",
    ContentComponent: DangKyThanhCongPage,
  },
  {
    path: "/quen-mat-khau",
    exact: true,
    name: "Login",
    ContentComponent: ForgotPassword,
  },
  {
    path: "/lien-he",
    exact: true,
    name: "LienHe",
    ContentComponent: ContactPage,
  },
  {
    path: "/dieu-khoan",
    exact: true,
    name: "DieuKhoan",
    ContentComponent: DieuKhoan,
  },
];

export default routes;
