import React from 'react'
import { Link } from 'react-router-dom'

const CustomLink = ({ text, url, icon, className }) => {
  return (
    <Link to={url}><a className={`customLink ${className}`}>{text} {icon && <img src='/images/commons/arr-right.svg' alt='icon' />}</a></Link >
  )
}

export default CustomLink
