/* eslint-disable no-extend-native */
import {
  Grid
} from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useTheme } from "@material-ui/styles";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Bar, BarChart, CartesianGrid, Cell, ComposedChart, Legend, Line, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { getListChannel } from "../../apis/channels";
import { getStatisticRevenue, getStatisticVideos } from "../../apis/dashboard";
import Dot from "../../components/Sidebar/components/Dot";
import Widget from "../../components/Widget";
import { Typography } from "../../components/Wrappers";
// styles
import useStyles from "./styles";


const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
Date.prototype.addDays = function (days) {
  var dat = new Date(this.valueOf())
  dat.setDate(dat.getDate() + days);
  return dat;
}

Date.prototype.addHours = function (hours) {
  var dat = new Date(this.valueOf()).getHours()
  dat.setDate(dat.getHours() + hours);
  return dat;
}


const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, name, index, ...rest }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <>
      <text x={x - 2} y={y - 15} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${name}`}
      </text>
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </>
  );
};
function getDates(startDate, stopDate) {
  var dateArray = new Array();
  var currentDate = new Date(startDate);
  while (currentDate <= new Date(stopDate)) {
    dateArray.push(currentDate)
    currentDate = currentDate.addDays(1);
  }
  return dateArray;
}
function getHours() {
  let stopDate = new Date().getTime() - (12 * 3600000);
  var dateArray = new Array();
  var currentDate = new Date().getTime();
  while (currentDate >= stopDate) {
    dateArray.push(new Date(currentDate).getHours())
    currentDate = currentDate - 3600000;
  }
  return dateArray;
}
export default function Chart(props) {
  var classes = useStyles();
  var theme = useTheme();
  // local
  var [videos, setVideos] = useState(null);
  var [viewByHour, setViewByHour] = useState(null);
  var [revenue, setRevenue] = useState(null);
  var [channelNumner, setChannelNumner] = useState(0);
  var [totalRevenue, setTotalRevenue] = useState(0);
  var [groupTotalValue, setGroupTotalValue] = useState([]);
  var [groupTotalRevenue, setGroupTotalRevenue] = useState(null);
  const date = new Date();
  const [dateRange, setDateRange] = useState([new Date(date.getFullYear(), date.getMonth(), 1), date]);
  const [startDate, endDate] = dateRange;
  useEffect(() => {
    (async () => {
      const list = await getListChannel({ page: 1, limit: 1 })
      setChannelNumner(list?.data?.total)
    })()

  }, [])
  useEffect(() => {
    if (endDate && startDate) {
      (async () => {
        const data = await getStatisticVideos({
          "sort_dir": "asc",
          "group_by": ["partner", "year", "month", "day"],
          from: startDate ? Math.ceil(startDate?.getTime() / 1000) : undefined,
          to: endDate ? Math.ceil(endDate?.getTime() / 1000) : undefined,
        })
        const dates = getDates(startDate, endDate)
        const groupData = groupBy(data?.data, 'partner');
        let groupTotalValue = [];
        let percents = []

        Object.keys(groupData).forEach(function (key) {
          let initialValue = 0
          let sum = groupData[key]?.reduce(function (previousValue, currentValue) {
            return previousValue + (currentValue?.choose_count || 0)
          }, initialValue)
          groupTotalValue.push({ name: key, value: sum });
        })
        setGroupTotalValue(groupTotalValue)
        const arrData = dates?.map((day, i) => {
          let item = { name: moment(day).format('D-M-YYYY') }
          let itemPercent = { name: moment(day).format('D-M-YYYY') }
          // let item = {}
          let total = 0;
          Object.keys(groupData).forEach(function (key) {
            const subData = groupData[key]?.find(data => {
              return `${data.day}-${data.month}-${data.year}` === moment(day).format('D-M-YYYY');
            })
            total += subData?.choose_count || 0;

          });
          Object.keys(groupData).forEach(function (key) {
            const subData = groupData[key]?.find(data => {
              return `${data.day}-${data.month}-${data.year}` === moment(day).format('D-M-YYYY');
            })
            item[key] = subData?.choose_count || 0;
            itemPercent[key] = (subData?.choose_count && total) ? subData?.choose_count / total : 0;
          });
          percents.push(itemPercent);
          return item;
        })
        setVideos(arrData)
        const dataRevenue = await getStatisticRevenue({
          limit: 30,
          "sort_dir": "asc",
          from: startDate ? Math.ceil(startDate?.getTime() / 1000) : undefined,
          to: endDate ? Math.ceil(endDate?.getTime() / 1000) : undefined,
        })
        console.log("🚀 ~ file: Chart.js ~ line 150 ~ arrDataRevenue ~ percents", percents)
        const arrDataRevenue = percents?.map((day, i) => {
          const revenueValue = dataRevenue?.data?.data.find(item => `${item.day}-${item.month}-${item.year}` === day?.name)?.estimated_revenue || 0;
          let revenueReData = { ...day }
          Object.keys(day).map(function (key) {
            if (key !== 'name') {
              revenueReData[key] = Math.round(day[key] * revenueValue * 100) / 100;
            }
          })
          return revenueReData;
        })
        let total = 0;
        dataRevenue?.data?.data?.map(item => {
          total += Math.round((item?.gross_revenue || 0) * 100) / 100
        })
        setTotalRevenue(total)
        setRevenue(arrDataRevenue)
        let tpm = { ...arrDataRevenue[0] }
        arrDataRevenue?.map((item, i) => {
          if (i > 0) {
            Object.keys(tpm).map(function (key, i) {
              if (key !== 'name') {
                tpm[key] = Math.round(tpm[key] * 100) / 100 + Math.round(item[key] * 100) / 100;
              }
            })
          }
        })
        tpm.name = 'Tổng Doanh Thu';
        setGroupTotalRevenue(tpm)
      })()
    }
  }, [endDate, startDate]);
  useEffect(() => {
    (async () => {
      const startTime = Math.ceil((new Date().getTime()) / 1000)
      const endTime = Math.ceil((new Date().getTime() - 12 * 3600000) / 1000)
      const data = await getStatisticVideos({
        "sort_dir": "asc",
        "group_by": ["partner", "year", "month", "day", "hour"],
        to: startTime,
        from: endTime,
      })
      const hours = getHours()
      const groupData = groupBy(data?.data, 'partner');

      const arrData = hours?.reverse()?.map((hour, i) => {
        let item = { name: hour }
        Object.keys(groupData).forEach(function (key) {
          const subData = groupData[key]?.find(data => {
            return data.hour === hour;
          })
          item[key] = subData?.choose_count || 0;
        });
        return item;
      })
      setViewByHour(arrData)
    })()
  }, []);
  const colors = {
    1: 'gray',
    2: 'blue',
    3: 'orange',
    4: 'green',
    5: 'brown',
    6: 'purple',
  }
  return (
    <>
      <Grid container direction={"row"} alignItems={"stretch"} spacing={4}>
        <Grid item xs={12} md={12}>
          <div style={{ display: 'flex', columnGap: 30, marginBottom: 30, marginTop: 15 }}>
            <Card className={classes.root} style={{ borderTop: '5px solid #04A8AB' }}>
              <CardContent className={classes.contentRoot}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Tổng thiết bị
                </Typography>
                <Typography className={classes.value} variant="h6" component="h6">
                  {props?.machines}
                </Typography>
              </CardContent>
            </Card>
            <Card className={classes.root} style={{ borderTop: '5px solid #C91312' }}>
              <CardContent className={classes.contentRoot}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Số kênh
                </Typography>
                <Typography className={classes.value} variant="h6" component="h6">
                  {channelNumner}
                </Typography>
              </CardContent>
            </Card>
            <Card className={classes.root} style={{ borderTop: '5px solid #117884' }}>
              <CardContent className={classes.contentRoot}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Doanh thu
                </Typography>
                <Typography className={classes.value} variant="h6" component="h6">
                  {totalRevenue}
                </Typography>
              </CardContent>
            </Card>
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <Widget
            bodyClass={classes.mainChartBody}
            header={
              <div style={{ width: '100%' }}>
                <div className={classes.mainChartHeader} style={{ marginTop: 10, marginBottom: 30 }}>
                  <Typography
                    variant="h5"
                    color="text"
                    colorBrightness="secondary"
                  >
                    Công suất vận hành
                  </Typography>
                  <div className={classes.mainChartHeaderLabels}>
                    {
                      videos?.length > 0 && Object.entries(videos[0]).map(
                        ([key], i) => {
                          if (key === 'name') {
                            return null
                          }
                          return (
                            <div className={classes.mainChartHeaderLabel}>
                              <Dot color={colors[i + 1]} />
                              <Typography className={classes.mainChartLegentElement}>
                                {key}
                              </Typography>
                            </div>
                          )
                        }
                      )}
                  </div>

                  <div>
                    <DatePicker
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => {
                        setDateRange(update);
                      }}
                    />
                  </div>
                </div>
              </div>
            }
          >
            <Grid container direction={"row"} alignItems={"stretch"} spacing={4}>
              <Grid item xs={12} md={8}>
                <ResponsiveContainer width="100%" minWidth={500} height={350}>
                  <ComposedChart
                    margin={{ top: 0, right: -15, left: 0, bottom: 0 }}
                    data={videos}
                  >
                    <YAxis
                      tick={{ fill: theme.palette.text.hint + "80", fontSize: 14 }}
                      stroke={theme.palette.text.hint + "80"}
                      tickLine={false}
                    />
                    <XAxis
                      tick={{ fill: theme.palette.text.hint + "80", fontSize: 14 }}
                      stroke={theme.palette.text.hint + "80"}
                      tickLine={false}
                      dataKey="name"
                    />
                    <Tooltip />
                    <Legend />
                    <CartesianGrid strokeDasharray="3 3" />
                    {
                      videos?.length > 0 && Object.entries(videos[0]).map(
                        ([key], i) => {
                          if (key === 'name') {
                            return null
                          }
                          const color = colors[i + 1]
                          return (
                            <Line
                              type="natural"
                              dataKey={key}
                              stroke={color}
                              strokeWidth={2}
                              dot={false}
                              activeDot={false}
                            />
                          )
                        }
                      )}
                  </ComposedChart>
                </ResponsiveContainer>
              </Grid>
              <Grid item xs={12} md={4}>
                <PieChart width={300} height={300}>
                  <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={groupTotalValue}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={120}
                    fill="#8884d8"
                    label={renderCustomizedLabel}
                  >
                    {groupTotalValue.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index + 2]}></Cell>
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </Grid>
            </Grid>

          </Widget>
        </Grid>
      </Grid>
      <Grid container direction={"row"} alignItems={"stretch"} spacing={4}>
        <Grid item xs={12} md={12}>
          <Widget
            bodyClass={classes.mainChartBody}
            header={
              <div style={{ width: '100%' }}>
                <div className={classes.mainChartHeader} style={{ marginTop: 10, marginBottom: 30 }}>
                  <Typography
                    variant="h5"
                    color="text"
                    colorBrightness="secondary"
                  >
                    Công suất vận hành 12h gần nhất
                  </Typography>
                  <div className={classes.mainChartHeaderLabels}>
                    {
                      videos?.length > 0 && Object.entries(videos[0]).map(
                        ([key], i) => {
                          if (key === 'name') {
                            return null
                          }
                          return (
                            <div className={classes.mainChartHeaderLabel}>
                              <Dot color={colors[i + 1]} />
                              <Typography className={classes.mainChartLegentElement}>
                                {key}
                              </Typography>
                            </div>
                          )
                        }
                      )}
                  </div>
                </div>
              </div>
            }
          >
            <Grid container direction={"row"} alignItems={"stretch"} spacing={4}>
              <Grid item xs={12} md={12}>
                <ResponsiveContainer width="100%" minWidth={500} height={350}>
                  <ComposedChart
                    margin={{ top: 0, right: -15, left: 0, bottom: 0 }}
                    data={viewByHour?.map(item => ({ ...item, name: `${item.name}h` }))}
                  >
                    <YAxis
                      tick={{ fill: theme.palette.text.hint + "80", fontSize: 14 }}
                      stroke={theme.palette.text.hint + "80"}
                      tickLine={false}
                    />
                    <XAxis
                      tick={{ fill: theme.palette.text.hint + "80", fontSize: 14 }}
                      stroke={theme.palette.text.hint + "80"}
                      tickLine={false}
                      dataKey="name"
                    />
                    <Tooltip />
                    <Legend />
                    <CartesianGrid strokeDasharray="3 3" />
                    {
                      viewByHour?.length > 0 && Object.entries(viewByHour[0]).map(
                        ([key], i) => {
                          if (key === 'name') {
                            return null
                          }
                          const color = colors[i + 1]
                          return (
                            <Line
                              type="natural"
                              dataKey={key}
                              stroke={color}
                              strokeWidth={2}
                              dot={false}
                              activeDot={false}
                            />
                          )
                        }
                      )}
                  </ComposedChart>
                </ResponsiveContainer>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          <Widget
            bodyClass={classes.mainChartBody}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h5"
                  color="text"
                  colorBrightness="secondary"
                >
                  Doanh thu
                </Typography>
                <div className={classes.mainChartHeaderLabels}>
                  {
                    revenue?.length > 0 && Object.entries(revenue[0]).map(
                      ([key], i) => {
                        if (key === 'name') {
                          return null
                        }
                        return (
                          <div className={classes.mainChartHeaderLabel}>
                            <Dot color={colors[i + 1]} />
                            <Typography className={classes.mainChartLegentElement}>
                              {key}
                            </Typography>
                          </div>
                        )
                      }
                    )}
                </div>
              </div>
            }
          >
            <Grid container>
              <Grid item xs={12} md={8}>
                <ResponsiveContainer width="100%" minWidth={500} height={350}>
                  <ComposedChart
                    margin={{ top: 0, right: -15, left: 0, bottom: 0 }}
                    data={revenue}
                  // data={revenue?.filter((item, i) => i !== 0)}
                  >
                    <YAxis
                      tick={{ fill: theme.palette.text.hint + "80", fontSize: 14 }}
                      stroke={theme.palette.text.hint + "80"}
                      tickLine={false}
                    />
                    <XAxis
                      tick={{ fill: theme.palette.text.hint + "80", fontSize: 14 }}
                      stroke={theme.palette.text.hint + "80"}
                      tickLine={false}
                      dataKey="name"
                    />
                    <Tooltip />
                    <Legend />
                    <CartesianGrid strokeDasharray="3 3" />
                    {
                      revenue?.length > 0 && Object.entries(revenue[0]).map(
                        ([key], i) => {
                          if (key === 'name') {
                            return null
                          }
                          const color = colors[i + 1]
                          return (
                            <Line
                              type="natural"
                              dataKey={key}
                              stroke={color}
                              strokeWidth={2}
                              dot={false}
                              activeDot={false}
                            />
                          )
                        }
                      )}
                  </ComposedChart>
                </ResponsiveContainer>
              </Grid>
              <Grid item xs={12} md={4}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={[groupTotalRevenue]}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    {/* <Legend /> */}
                    {
                      groupTotalRevenue && Object.entries(groupTotalRevenue).map(
                        ([key], i) => {
                          if (key === 'name') {
                            return null
                          }
                          return (
                            <Bar dataKey={key} fill={colors[i + 1]} />
                          )
                        }
                      )}
                  </BarChart>
                </ResponsiveContainer>
              </Grid>
            </Grid>
          </Widget>
        </Grid >

      </Grid >
    </>
  );
}