import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const IndexPage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="serviceDetail">
      <div className="container">
        <h1 className="customHeading text-center">
          Dịch vụ tăng view cho video trên Youtube
        </h1>
        <h3>Tăng lượt view cho video của bạn</h3>
        <p>Đưa video của bạn lên đề xuất cao</p>
        <p>
          Các video của bạn sẽ không có người vào xem. Vì có quá ít lượt tương
          tác nên cho dù video của bạn có hay đến mấy mà không có lượt tương tác
          hỗ trợ ban đầu sẽ rất khó để trở thành viral.
        </p>
        <p>
          Chúng tôi hiểu được thuật toán của youtube. Từ đó chúng tôi sẽ giúp
          các video của bạn có những lượt xem tốt.
        </p>
        {/* <img src="/images/services/traffic-vietnam.jpeg" alt="" /> */}
        <h3>Dịch Vụ Của Chúng Tôi Có Gì Đặc Biệt?</h3>
        <p>
          Các traffic view bạn nhận được là traffic thực chất lượng cao. Đảm bảo
          thống kê được trên các công cụ thống kê google hoặc công cụ khác.
          Lượng tương tác thực cao với nguồn truy cập đa dạng. 100% tốt cho SEO.
          Dịch vụ tăng Traffic mà chúng tôi cung cấp đến khách hàng là traffic
          thật 100% do sự trao giữa các thành viên với nhau, nên số lượng
          traffic về view vừa phải, với các nguồn từ tìm kiếm tự nhiên Youtube,
          Từ click đề xuất vừa tăng chất lượng cho kênh và cho cả video của bạn.
        </p>
      </div>
    </div>
  );
};
export default IndexPage;
