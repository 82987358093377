const Solution = () => {
  return (
    <div className='solutionWrap'>
      <div className='container'>
        <div className='solutionContainer'>
          <div className='solutionLeft'>
            <h3>Giải pháp</h3>
            <p>Với kiến thức chuyên sâu về youtube kết hợp với nền tảng công nghệ hiện đại.
              Giải pháp của chúng tôi đáp ứng được hàng nghìn đối tác và hàng triệu lượt truy cập. Nền tảng vững chắc đó đã thúc đẩy chúng tối đi tìm các đối tác để cùng nhau phát triển và lan tỏa thành công này. </p>
          </div>
          <div className='solutionLeft'>
            <h3>Công nghệ</h3>
            <p>Chúng tôi đã xây dựng mội đội ngũ chuyên gia tài năng và nhiệt huyết, cập nhật liên tục công nghệ hiện đại để đáp ứng kịp thời thay đổi của Youtube, hỗ trợ đối tác 24/7.</p>
          </div>
        </div>
        <img src='/images/about/about2.jpg' alt='' />
      </div>
    </div>
  )
}

export default Solution
