import request from "../helpers/request";

export function depositList(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/customers/deposit/list",
    method: "get",
    withoutLoading: true,
    withoutError: true,
    params,
  });
}
export function depositCreate(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/customers/deposit/create",
    method: "post",
    data: data,
  });
}
export function depositCancel(uid) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/customers/deposit/cancel",
    method: "post",
    data: { uid: uid },
  });
}
