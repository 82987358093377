import request from "../helpers/request";

export function walletGet(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/customers/wallet/get",
    method: "post",
    withoutLoading: true,
    withoutError: true,
    params,
  });
}
