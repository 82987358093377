import React, { useState, useEffect, useRef } from "react";

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

const TokenKey = "user_info";
const UserKey = "user_name";

export function getToken() {
  return localStorage.get(TokenKey);
}
export function getUser() {
  return localStorage.get(UserKey);
}

export function setToken(token) {
  return localStorage.set(TokenKey, token);
}

export function setUser(name) {
  return localStorage.set(UserKey, name);
}

export function isLogged() {
  return localStorage.get(TokenKey);
}

export function logout() {
  return localStorage.set(TokenKey, "");
}

export function hasPermission(permission = []) {
  const roles = JSON.parse(localStorage.getItem("roles") || []);
  return permission.find((per) => roles.find((role) => role === per));
}

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getNowUnix() {
  return Math.floor(new Date().getTime() / 1000);
}

export function secondsToHms(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h === 1 ? "h," : "h,") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? "m," : "m,") : "";
  var sDisplay = s > 0 ? s + (s === 1 ? "s" : "s") : "";
  return hDisplay + mDisplay + sDisplay;
}

export function convertTimestampToDateTime(timestamp) {
  var currentTimestamp = timestamp * 1000;
  let date = new Intl.DateTimeFormat("vi-VN", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }).format(currentTimestamp);
  return date;
}
export function numberWithCommas(x) {
  if (x !== 0 && !x) {
    return "err unit";
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function secondsToDhms(seconds) {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor(seconds % 60);

  var dDisplay = d > 0 ? d + (d === 1 ? "d" : "d") : "";
  var hDisplay = h > 0 ? h + (h === 1 ? "h" : "h") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? "m" : "m") : "";
  var sDisplay = s > 0 ? s + (s === 1 ? "s" : "s") : "";
  return dDisplay + hDisplay + mDisplay + sDisplay;
}

export function convertStringWithNewLine(strInput) {
  let output;
  output = strInput.split(",").map((item, i) => {
    return <p key={i}> {item}</p>;
  });
  // console.log(output);
  // // let newText = strInput.split(',').map((item, i) => <p key={i}> {item}</p>);
  return output;
  return strInput.split(",").join(",");
}

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    "Bytes",
    "KiB",
    "MiB",
    "GiB",
    "TiB",
    "PiB",
    "EiB",
    "ZiB",
    "YiB",
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
