import ServiceItem from '../../components/Services/ServiceItem/ServiceItem'

const services = [
  {
    title: 'Dịch vụ tăng view cho website',
    desc: `Từ những ngày đầu YTService được phát triển từ nhu cầu thực tế của cá nhân và mong muốn hỗ trợ cộng đồng đạt được lượng truy cập cần thiết cho website mà không cần phải đầu tư vào những chiến dịch quảng cáo tốn kém.
    Ưu điểm của tăng view cho website :
    <ul>
      <li>Tăng traffic miễn phí, giảm tỷ lệ thoát, tăng rank alexa.</li>
      <li>Tự động tìm từ khóa của bạn trên Google và click.</li>
      <li>Tự động click backlink từ website khác về website của bạn.</li>
    </ul>`,
    img: '/images/services/view.jpeg',
    imageLeft: false,
    url: '/dich-vu/tang-view-website'
  },
  {
    title: 'Bán video youtube độc lạ ở Việt Nam',
    desc: `Nếu bạn đang có nhu cầu phát triển hoặc muốn duy trì kênh Youtube, thì <a href="https://YTServicemet.com" target="_blank">https://YTServicemet.com</a> là lựa chọn tốt nhất cho bạn trong thời điểm hiện tại. Chúng tôi cung cấp video chất lượng và đảm bảo giá thành cạnh tranh. Đội ngũ làm việc trẻ và năng động được đào tạo chuyên nghiệp đem đến video clip chất lượng sáng tạo tốt nhất cho bạn.`,
    img: '/images/services/service2.png',
    imageLeft: true,
    url: '/dich-vu/ban-video-doc-la'
  },
  {
    title: 'Dịch vụ tăng view cho video trên Youtube',
    desc: `Video nhiều view dễ lên top hơn video ít view, đây chính là đặc quyền youtube dành cho các kênh youtube chất lượng. Người dùng tìm kiếm video trên youtube bằng các từ khóa sau đó youtube sẽ trả về kết quả bằng các video. Nếu video của bạn có nhiều lượt xem bạn sẽ được ưu tiên đứng trước các video khác, từ đó người dùng sẽ dễ click và xem video của bạn hơn. Đây cũng là cách để view youtube của bạn ngày càng tăng hơn và không tụt khỏi top. Thậm chí nếu lượt xem video cao và có nhiều người quan tâm rất có thể video của bạn sẽ xuất hiện ở tab thịnh hành.`,
    img: '/images/services/service3-l.jpeg',
    imageLeft: false,
    url: '/dich-vu/tang-view-video-youtube'
  }
]
const ServicePage = () => {
  return (
    <div className='services'>
      {
        services?.map(service => <ServiceItem {...service} />)
      }
    </div>
  )
}
export default ServicePage
