const CSItem = ({ name, add, desc, img, date }) => {
  return (
    <div className={`csItem`}>
      <img src={img} alt={name} />
      <div className='csDate'>{date}</div>
      <div className='csName'>{name}</div>
      <div className='csDesc'>{desc}</div>
      <div className='csAdd'>{add}</div>
    </div>
  )
}
const list = [
  {
    name: 'Cơ sở Đặng Trần Côn Hải Phòng',
    img: '/images/address/cs1.jpeg',
    date: '09/03/2022',
    desc: 'Gói combo Platinum',
    add: 'Khu đô thị Đô Nghĩa, Y ên Nghĩa, Hà Đông'
  },
  {
    name: 'Cơ sở Đặng Trần Côn Hải Phòng',
    img: '/images/address/cs2.jpeg',
    date: '09/03/2022',
    desc: 'Gói combo Platinum',
    add: 'Khu đô thị Đô Nghĩa, Y ên Nghĩa, Hà Đông'
  },
  {
    name: 'Cơ sở Đặng Trần Côn Hải Phòng',
    img: '/images/address/cs3.jpeg',
    date: '09/03/2022',
    desc: 'Gói combo Platinum',
    add: 'Khu đô thị Đô Nghĩa, Y ên Nghĩa, Hà Đông'
  },
  {
    name: 'Cơ sở Đặng Trần Côn Hải Phòng',
    img: '/images/address/cs1.jpeg',
    date: '09/03/2022',
    desc: 'Gói combo Platinum',
    add: 'Khu đô thị Đô Nghĩa, Y ên Nghĩa, Hà Đông'
  },
  {
    name: 'Cơ sở Đặng Trần Côn Hải Phòng',
    img: '/images/address/cs2.jpeg',
    date: '09/03/2022',
    desc: 'Gói combo Platinum',
    add: 'Khu đô thị Đô Nghĩa, Y ên Nghĩa, Hà Đông'
  },
  {
    name: 'Cơ sở Đặng Trần Côn Hải Phòng',
    img: '/images/address/cs3.jpeg',
    date: '09/03/2022',
    desc: 'Gói combo Platinum',
    add: 'Khu đô thị Đô Nghĩa, Y ên Nghĩa, Hà Đông'
  }
]
const CSPage = () => {
  return (
    <div className='stepWrap'>
      <div className='container'>
        <h1 className='customHeading text-center'>Danh sách cơ sở</h1>
        <div className='csWrap'>
          {
            list.map(item => (
              <CSItem {...item} />
            ))
          }</div>
      </div>
    </div>
  )
}
export default CSPage
