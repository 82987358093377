import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ContactPage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="contactWrap">
      <div className="container">
        <h1 className="customHeading text-center">Đăng ký thành công</h1>
        <p>
          Bạn đã đăng ký thành công dịch vụ của chúng tôi. Đường dẫn tài khoản
          đã được gửi đến email của bạn. Vui lòng vào email và kích hoạt tài
          khoản. Trân trọng cảm ơn !
        </p>
      </div>
    </div>
  );
};
export default ContactPage;
