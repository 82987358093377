import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import CustomizedSnackbars from "../components/SnackBar/SnackBar";
import FullLoading from "../components/Loading/FullLoading";

// create an axios instance
const service = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 250000, // request timeout
});

let withoutError = false;
// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent

    if (localStorage.getItem("token")) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers["Authorization"] = localStorage.getItem("token");
    }
    if (!config?.withoutLoading) {
      ReactDOM.render(<FullLoading open />, document.getElementById("loading"));
    }
    withoutError = config?.withoutError;
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  },
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    // console.log('response', response)
    const res = response.data;

    ReactDOM.render(<></>, document.getElementById("loading"));
    // if the custom code is not 20000, it is judged as an error.
    // if (res.code !== 200) {
    //   CustomizedSnackbars({
    //     message: res.message || 'Error',
    //     severity: 'error',
    //     open: true,
    //   })
    // }
    // console.log('res11111', res)
    return res;
    //   // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    // //   if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
    // //     // to re-login
    // //     MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
    // //       confirmButtonText: 'Re-Login',
    // //       cancelButtonText: 'Cancel',
    // //       type: 'warning'
    // //     }).then(() => {
    // //       store.dispatch('user/resetToken').then(() => {
    // //         location.reload()
    // //       })
    // //     })
    // //   }
    //   return Promise.reject(new Error(res.message || 'Error'))
    // } else {
    //   return res
    // }
  },
  (error) => {
    console.log("err" + error.message); // for debug
    ReactDOM.render(
      <CustomizedSnackbars
        message={`Error ${error?.message}` || "Error"}
        severity="error"
        open
      />,
      document.getElementById("noti"),
    );

    ReactDOM.render(<></>, document.getElementById("loading"));

    return Promise.reject(error);
  },
);

export default service;
