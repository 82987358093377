import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const IndexPage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className='serviceDetail'>
      <div className='container'>
        <h1 className='customHeading text-center'>Bán video youtube độc lạ ở Việt Nam</h1>
        <h3>Tăng Lượng Truy Cập Website Có Lợi Gì</h3>
        <p>Nhờ vào đó giúp Google index nhanh, đánh giá cao website và đưa lên TOP nhanh hơn. dịch vụ tăng traffic hỗ trợ SEO Dịch vụ tăng traffic chất lượng có thể Kéo traffic từ nguồn mà mình muôn như : Google, Facebook hay Bing,… Kéo traffic từ địa điểm mình mong muốn như : Việt Nam, UK, Canada, Singapore, hay Ân độ...
        </p>
        <p>
          Hãy thử hình dung các website cũng như các cửa hàng, nếu cửa hàng nào có nhiều người đến xem sẽ được những người có nhu cầu tình cờ đi ngang qua sẽ ghé vào tham quan. Cửa hàng nào có quá ít người vào thì chắc chắn những người có nhu cầu sẽ e ngại (do không biết cửa hàng này thế nào mà không thấy ai vào xem cả) nên không ghé vào, làm mất đi cơ hội bán hàng của bạn.
        </p><p>
          Đối với website, khi khách hàng truy cập vào và nhìn thấy có ít người online, ít người truy cập mỗi ngày thì khách hàng sẽ không tin tưởng để giao dịch, khách hàng sẽ đặt vấn đề về chất lượng dịch vụ mà website bạn cung cấp. Ngoài ra, do có quá ít lượt truy cập hàng ngày, website của bạn sẽ bị xếp hạng thấp trên Google và Alexa, ảnh hưởng lớn đến uy tín website của bạn.
        </p>
        <img src='/images/services/traffic-vietnam.jpeg' alt='' />
        <h3>Dịch Vụ Của Chúng Tôi Có Gì Đặc Biệt?</h3>
        <p>Các traffic website bạn nhận được là traffic thực chất lượng cao. Đảm bảo thống kê được trên các công cụ thống kê google hoặc công cụ khác. Lượng tương tác thực cao với nguồn truy cập đa dạng. 100% tốt cho SEO.
          Dịch vụ tăng Traffic mà chúng tôi cung cấp đến khách hàng là traffic thật 100% do sự trao giữa các thành viên với nhau, nên số lượng traffic về website vừa phải, thời gian truy câp lâu trên 2 phút, giảm tỉ lệ thoát tốt, với các nguồn từ tìm kiếm tự nhiên Google, từ Mạng Xã Hội, Từ click Backlink vừa tăng chất lượng backlink vừa đa dạng nguồn Traffic rất tốt cho Seo.</p>
        <ul>
          <li>Tăng Traffic từ mạng xã hội: Facebook, G+… hoạt động chia sẻ thông tin bài viết để các thành viên tham gia trao đổi View.</li>
          <li>Tăng Traffic từ tìm kiếm trên các công cụ tìm kiếm: người dùng tìm kiếm từ khóa trên Google, Bing,…</li>
          <li>Tăng Traffic từ truy cập trực tiếp: dựa vào nội dung website, bạn sẽ phải cập nhật nội dung mới để khách viếng thăm quay trở lại.</li>
        </ul>
      </div>
    </div>
  )
}
export default IndexPage
