import request from "../helpers/request";

export function registerFunc(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/customers/register",
    method: "post",
    data,
  });
}

export function loginFunc(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/customers/login",
    method: "post",
    data,
  });
}
