import {
    TextField
} from "@material-ui/core";
import InputAdornment from '@material-ui/core/InputAdornment';
import { useState } from "react";
import useStyles from "./styles";


const ForgotPassword = ({ props }) => {
    const classes = useStyles()
    // local
    var [loginValue, setLoginValue] = useState("");
    var [passwordValue, setPasswordValue] = useState("");
    var [showPassword, setShowPassword] = useState("");

    return (
        <div className="loginContainer">
            <div className="container">
                <div className="loginWrap">
                    <div className="loginLeft">
                        <img src="/images/commons/login.svg" alt="login" />
                    </div>
                    <div className="loginRight">
                        <h2>Quên mật khẩu</h2>
                        <p>Mời bạn vào email đã đăng ký lấy lại mật khẩu</p>
                        <p style={{ marginBottom: 30 }}>Hoặc liên hệ với hotline :<strong>0985 123 456</strong></p>
                        <TextField
                            id="email"
                            InputProps={{
                                classes: {
                                    underline: classes.textFieldUnderline,
                                    input: classes.textField,
                                },
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img src="/images/commons/email.svg" alt="" />
                                    </InputAdornment>
                                ),
                            }}
                            value={loginValue}
                            onChange={e => setLoginValue(e.target.value)}
                            margin="normal"
                            placeholder="Nhập địa chỉ email của bạn"
                            type="email"
                            label="Email"
                            fullWidth
                            className="customInput"
                        />
                        <div>
                        </div>
                        <button className="button red" disabled={
                            loginValue.length === 0 || passwordValue.length === 0
                        }
                            onClick={() => { }
                            }>Gửi thông tin</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ForgotPassword