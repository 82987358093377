import request from "../helpers/request";

export function proxyVietList(params) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/customers/proxy-viet/list",
    method: "get",
    withoutLoading: true,
    withoutError: true,
    params,
  });
}
export function proxyVietPricings(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/customers/proxy-viet/pricings",
    method: "post",
    data: data,
  });
}
export function proxyVietOrders(data) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/customers/proxy-viet/orders",
    method: "post",
    data: data,
  });
}
export function proxyVietExtends(uids) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/customers/proxy-viet/extends",
    method: "post",
    data: { uids: uids },
  });
}
export function proxyVietChangeIps(uids) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/customers/proxy-viet/change-ips",
    method: "post",
    data: { uids: uids },
  });
}
export function proxyVietChangeIpDuration(uids, changeIpDuration) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/customers/proxy-viet/change-ip-duration",
    method: "post",
    data: { uids: uids, change_ip_duration: changeIpDuration },
  });
}
export function proxyVietChangeUserPass(uids, userProxy, passProxy, isRandom) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/customers/proxy-viet/change-user-pass",
    method: "post",
    data: {
      uids: uids,
      user_proxy: userProxy,
      pass_proxy: passProxy,
      is_random: isRandom,
    },
  });
}
export function proxyVietDelete(uids) {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: "/customers/proxy-viet/delete",
    method: "delete",
    data: {
      uids: uids,
    },
  });
}
