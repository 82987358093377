import { AppBar, IconButton, Menu, Toolbar, Button } from "@material-ui/core";
import {
  ArrowBack as ArrowBackIcon,
  Menu as MenuIcon,
  Person as AccountIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import React, { useState, useEffect } from "react";
// context
import {
  toggleSidebar,
  useLayoutDispatch,
  useLayoutState,
} from "../../context/LayoutContext";
import {
  signOut,
  userNapTien,
  useUserDispatch,
} from "../../context/UserContext";
// components
import { Typography } from "../Wrappers";
import { walletGet } from "../../apis/wallet";
// styles
import useStyles from "./styles";
import { numberWithCommas } from "../../helpers/utils";

export default function Header(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  var [profileMenu, setProfileMenu] = useState(null);
  var [wallet, setWallet] = useState(null);

  useEffect(() => {
    async function getData() {
      const wallet = await walletGet();
      setWallet(wallet);
    }
    getData();

    const timer1 = setInterval(async () => {
      await getData();
    }, 5000);

    return () => {
      clearInterval(timer1);
    };
  }, []);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        <Typography
          variant="h6"
          weight="medium"
          style={{ textTransform: "uppercase" }}
          className={classes.logotype}
        >
          {localStorage.getItem("user_name")}
        </Typography>
        <div className={classes.grow} />

        <Typography
          variant="h6"
          weight="medium"
          style={{ textTransform: "uppercase" }}
          className={classes.logotype}
        >
          {numberWithCommas(wallet?.main_balance)} (Kvnđ)
        </Typography>

        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={(e) => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>

        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h5" weight="medium">
              {localStorage.getItem("user_name")}
            </Typography>
          </div>
          <div
            style={{
              width: "100%",
            }}
          >
            <Button
              style={{
                width: "100%",
                margin: "1",
              }}
              onClick={() => userNapTien(userDispatch, props.history)}
            >
              Nạp tiền
            </Button>
          </div>
          <div
            style={{
              width: "100%",
            }}
          >
            <Button
              style={{
                width: "100%",
                margin: "1",
              }}
              color="secondary"
              onClick={() => signOut(userDispatch, props.history)}
            >
              Sign Out
            </Button>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
