import CustomLink from '../../commons/Link/Link'

const ServiceItem = ({
  title, img, desc, url, imageLeft
}) => {
  return (
    <div className={`itemWrap ${imageLeft ? 'imgLeft' : ''}`}>
      <div className='container'>
        <div className={`serviceItems`}>
          <div className='serviceItemLeft'>
            <h3 className='heading'>{title}</h3>
            <div dangerouslySetInnerHTML={{ __html: desc }} className='desc' />
            <CustomLink text='Xem chi tiết' url={url} icon className={'red'} />
          </div>
          <div className='serviceItemRight'>
            <img src={img} alt={title} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceItem
