import React from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
// components
import Layout from "./Layout";
import LayoutWeb from "../pages/website/components/Layout/Layout";

// pages
import Error from "../pages/error";

// context
import { useUserState } from "../context/UserContext";

export default function App() {
  // global
  var { isAuthenticated } = useUserState();

  return (
    <BrowserRouter>
      {/* <ScrollTop> */}
      <Switch>
        {/* {routes?.map(route => <PublicRoute path={route.path} component={LayoutWeb} />)} */}
        {/* {routes?.map(route => <Route exact path={route.path} render={() => <LayoutWeb>{route.ContentComponent}</LayoutWeb>} />)} */}

        <Route
          exact
          path="/app"
          render={() => <Redirect to="/app/view-orders" />}
        />
        <PrivateRoute path="/app" component={Layout} />
        <Route path="/" component={LayoutWeb} />
        <Route component={Error} />
      </Switch>
      {/* </ScrollTop> */}
    </BrowserRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }
}
