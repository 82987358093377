import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ContactPage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className='contactWrap'>
      <div className='container'>
        <h1 className='customHeading text-center'>Liên hệ</h1>
        <p>
          Quý khách có nhu cầu làm đối tác, vui lòng điền thông tin vào form bên dưới .
          Chúng tôi sẽ liên hệ với bạn trong thời gian sớm nhất để tư vấn.
          Trân trọng cảm ơn !
        </p>
        <div className="formContact">
          <form>
            <div className="rowForm">
              <input type='text' name='name' placeholder='Họ tên của bạn' />
              <input type='text' name='email' placeholder='Email liên hệ' />
            </div>
            <input type='text' name='phone' placeholder='Tiêu đề' />
            <textarea type='text' name='content' placeholder='Nội dung' />
            <button type='submit' className='button black'>Gửi thông tin</button>
          </form>
        </div>
      </div>
    </div>
  )
}
export default ContactPage
