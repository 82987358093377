import React from "react";
import CustomLink from "../../commons/Link/Link";

const listService = [
  {
    title: "Dịch vụ tăng view cho website",
    desc:
      "Từ những ngày đầu LuckyWind được phát triển từ nhu cầu thực tế của cá nhân và mong muốn hỗ trợ cộng đồng đạt được lượng truy cập cần thiết cho website .",
    img: "/images/services/service1.jpeg",
    link: "/dich-vu/tang-view-website",
  },
  // {
  //   title: "Bán video youtube độc lạ ở Việt Nam",
  //   desc: `Nếu bạn đang có nhu cầu phát triển hoặc muốn duy trì kênh Youtube, thì <a target="_blank" href="https://YTServicemet.com">https://YTServicemet.com</a> là lựa chọn tốt nhất cho bạn trong thời điểm hiện tại.`,
  //   img: "/images/services/service2.jpg",
  //   link: "/dich-vu",
  // },
  {
    title: "Dịch vụ tăng view video trên Youtube",
    desc: `Video nhiều view dễ lên top hơn video ít view, đây chính là đặc quyền youtube dành cho các kênh youtube chất lượng. `,
    img: "/images/services/service3.jpeg",
    link: "/dich-vu/tang-view-video-youtube",
  },
];
const Services = () => {
  return (
    <div className="servicesWrap">
      <div className="container">
        <div className="services">
          <div className="servicesHead">
            <h3 className="heading">Dịch vụ khác của chúng tôi</h3>
            <CustomLink
              url="/dich-vu"
              text="Xem chi tiết"
              icon
              className={"red"}
            />
          </div>
          <div className="serviceList">
            {listService?.map((item, i) => (
              <div key={i} className="serviceItem">
                <img src={item.img} alt={item.title} />
                <h3>{item.title}</h3>
                <div
                  dangerouslySetInnerHTML={{ __html: item.desc }}
                  className="desc"
                />
                <CustomLink
                  url={item.link}
                  text="Xem chi tiết"
                  icon
                  className={"red"}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
