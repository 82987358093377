import Coop from '../components/About/Coop/Coop'
import ImageBox from '../components/About/ImageBox/ImageBox'
import About from '../components/About/Introduct/About'
import OurTeam from '../components/About/OurTeam/OurTeam'
import Solution from '../components/About/Solution/Solution'

const AboutPage = () => {
    return (
        <>
            <About />
            <Solution />
            <Coop />
            <ImageBox />
            <OurTeam />
        </>
    )
}
export default AboutPage
