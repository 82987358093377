const About = () => {
  return (
    <div className='aboutWrap'>
      <div className='container aboutContainer'>
        <div className='aboutLeft'>
          <h1>Về chúng tôi</h1>
          <h2>5 năm nghiên cứu và tìm ra giải pháp và công nghệ</h2>
          <p>Hiện nay, với sự phổ biến của internet, sự tác động của Youtube đối với chúng ta là vô cùng lớn. Trung bình, mỗi người Việt dành ra 2 tiếng mỗi ngày để xem Youtube.  Nắm bắt xu hướng và thị trường, làm chủ công nghệ, chúng tôi mất 5 năm nghiên cứu và tìm hiểu giải pháp để có được thành quả như ngày hôm nay</p>
        </div>
        <div className='aboutRight'>
          <img src='/images/about/about.jpg' alt='About' />
        </div>
      </div>
    </div>
  )
}

export default About
