import React, { PureComponent } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import routes from "../../routes";

class Layout extends PureComponent {
  render() {
    return (
      <div className="layout">
        <header>
          <Header />
        </header>
        <Switch>
          {routes.map(({ path, ContentComponent }) => {
            return (
              <Route
                path={path}
                exact
                render={(props) => <ContentComponent {...props} />}
              />
            );
          })}
        </Switch>
        <footer>
          <Footer />
        </footer>
      </div>
    );
  }
}

export default Layout;
