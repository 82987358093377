import React from "react";
import Slider from "react-slick";

const HomeSlider = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="sliderHome">
      <div className="container">
        <Slider {...settings}>
          <div>
            <div className="sliderItem">
              <div className="sliderContent">
                <h3>Proxy Việt 4g chất lượng tốt nhất</h3>
                <p>
                  Chúng tôi xây dựng hạ tầng mạng lưới liên kết lớn với mong
                  muốn mở rộng dải ip cung cấp cho khách hàng ip chất lượng tốt
                  nhất
                </p>
                <a href="/dang-ky" className="button black">
                  <strong>Đăng ký</strong>
                </a>
              </div>
              <div className="sliderImg">
                <img src="/images/sliders/slider1.svg" alt="slider 1" />
              </div>
            </div>
          </div>

          <div>
            <div className="sliderItem">
              <div className="sliderContent">
                <h3>Băng thông vô hạn</h3>
                <p>
                  Cung cấp các gói băng thông vô hạn, các bạn có thể sử dụng
                  thoải mái mà ko lo hết dung lượng.
                </p>
                <a href="/dang-ky" className="button black">
                  <strong>Đăng ký</strong>
                </a>
              </div>
              <div className="sliderImg">
                <img src="/images/sliders/slider1.svg" alt="slider 1" />
              </div>
            </div>
          </div>

          <div>
            <div className="sliderItem">
              <div className="sliderContent">
                <h3>Tốc độ chuyển ip cực cao</h3>
                <p>
                  Công nghệ chung tôi có thể cập nhật ip mới 1 cách nhanh nhất.
                  Các bạn sẽ cảm thấy hài lòng.
                </p>
                <a href="/dang-ky" className="button black">
                  <strong>Đăng ký</strong>
                </a>
              </div>
              <div className="sliderImg">
                <img src="/images/sliders/slider1.svg" alt="slider 1" />
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default HomeSlider;
