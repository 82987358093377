import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export default function DialogCreate({ open, onClose, onSubmitForm }) {
  const classes = useStyles();
  const [amount, setAmount] = useState(0);
  const [unit, setUnit] = useState("VNĐ");
  return (
    <div>
      <form>
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="draggable-dialog-title"
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Nạp tiền
          </DialogTitle>
          {/* <DialogContent> 
          <div style={{ float: 'right' }}>
            {!channel?.length ? <AddBoxIcon onClick={() => { }} style={{ cursor: 'pointer' }} color="secondary" /> : <></>}
          </div>
        </DialogContent> */}

          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                style={{ margin: 10 }}
                type="number"
                id="outlined-multiline-static"
                label="Amount "
                placeholder="Số tiền nạp"
                onChange={(event) => {
                  setAmount(parseInt(event.target.value));
                }}
              />

              <FormControl className={classes.formControl}>
                <InputLabel
                  shrink
                  id="demo-simple-select-placeholder-label-label"
                >
                  Unit
                </InputLabel>
                <Select
                  value={unit}
                  onChange={(event) => setUnit(event.target.value)}
                  displayEmpty
                  className={classes.selectEmpty}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="VNĐ">
                    <em>VNĐ</em>
                  </MenuItem>
                  {[{ name: "VNĐ" }]?.map((pk) => (
                    <MenuItem key={pk.name} value={pk.name}>
                      {pk.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <DialogActions>
            <Button autoFocus onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={amount > 0 ? false : true}
              onClick={async () => {
                await onSubmitForm({
                  unit: unit,
                  amount: amount,
                });
              }}
            >
              Tạo giao dịch
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}
