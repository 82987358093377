const Step = ({ step, title, desc, img, imgLeft, bg }) => {
  return (
    <div className='stepContainer' style={{ background: bg }}>
      <div className='container'>
        <div className={`stepItem ${!imgLeft ? 'imgRight' : ''}`}>
          <div className='stepItemLeft'>
            <div className='stepName'>{step}</div>
            <h3>{title}</h3>
            <div dangerouslySetInnerHTML={{ __html: desc }} className='desc' />
          </div>
          <div className='stepItemRight'>
            <img src={img} alt='' />
          </div>
        </div>
      </div>
    </div>
  )
}
const steps = [
  {
    title: 'Nhà đầu tư tham khảo & YTService tư vấn hỗ trợ tư vấn',
    step: 'Bước 1',
    img: '/images/steps/step1.svg',
    desc: `
      <ul>
        <li>Nhà đầu tư tham khảo, nghiên cứu các gói dịch vụ</li>
        <li>YTService tư vấn miễn phí cho nhà đầu tư trong 24h.</li>
        <li>YTService trao đổi với nhà đầu tư các gói combo phù hợp với ngân sách và lợi nhuận.</li>
      </ul>
    `,
    imgLeft: true,
    bg: '#fff'
  },
  {
    title: 'Ký hợp đồng và setup hệ thống.',
    step: 'Bước 2',
    img: '/images/steps/step2.svg',
    desc: `<ul>
    <li>Hai bên kí hợp đồng.
    <li>Tiến hành lắp đặt – setup hệ thống (lắp đặt phần cứng, thiết bị)
    cài đặt phần mềm.)</li>
    <li>Upload nội dung lên kênh.</li>
    <li>Đưa nội dung trên kênh vào nền tảng.</li>
    <li>Tiến hành đối soát và thanh toán cho đối tác, nhà đầu tư theo hợp đồng thỏa thuận ( thường thanh toán vào ngày 22 hàng tháng).</li>
    </ul>
    `,
    imgLeft: false,
    bg: '#FFE4E4'
  },
  {
    title: 'Vận hành hệ thống',
    step: 'Bước 3',
    img: '/images/steps/step3.svg',
    desc: `
    Sau khi setup hệ thống. Dự án bắt đầu như sau:
    <ul>
    <li>Nhân viên tại cơ sở tiến hành mua video tại trang web:</li>
    <a href="http://YTServicemet.com" target="_blank">http://YTServicemet.com</a>. Và tải video lên các kênh youtube trong gói hợp đồng.</li>
    <li>Đưa các video vào hệ thống để tiến hành chạy view tự động</li>
    <li>Nhà đầu tư kiểm tra doanh thu thống kê hằng ngày tại account:….</li>
    <li>Ngày 22 hàng tháng công ty gửi đối soát cho đối tác và tiến hành thanh toán.</li>
    </ul>
    `,
    imgLeft: true,
    bg: 'rgba(252, 217, 128, 0.1);'
  }
]
const ProcessPage = () => {
  return (
    <div className='stepWrap'>
      <div className='container'>
        <h1 className='customHeading text-center'>3 bước để bạn khởi nghiệp thành tỷ phú</h1>
      </div>
      {
        steps.map(step => (
          <Step {...step} />
        ))
      }
    </div>
  )
}
export default ProcessPage
