import React from "react";
import { Link } from "react-router-dom";

import css from "./Footer.module.css";

const menus = [
  {
    link: "/",
    label: "Trang chủ",
  },
  {
    link: "/gioi-thieu",
    label: "Giới thiệu",
  },
  {
    link: "/bang-gia",
    label: "Bảng giá",
  },
  {
    link: "/dich-vu",
    label: "Dịch vụ",
  },
  {
    link: "/quy-trinh",
    label: "Quy trình",
  },
  {
    link: "/co-so",
    label: "Cơ sở",
  },
];
const Footer = () => {
  return (
    <>
      <div className={css.footerWrap}>
        <div className="container">
          <div className={css.footerInner}>
            <div className={css.footerLeft}>
              <img
                style={{ witdh: 150, height: 150 }}
                src="/images/commons/logo.svg"
                alt="Logo"
              />
              {/* <h3>Công ty TNHH LuckyWind</h3> */}
              <p></p>
              <div className={css.card}>
                <div className={css.item}>
                  <h4>Liên hệ telegram:</h4>
                  <p>@kapuchino_1234</p>
                </div>
                <div className={css.item}> </div>
              </div>
            </div>
            <div className={css.footerRight}>
              <p>
                Quý khách có nhu cầu làm đối tác, vui lòng liên hệ với chúng
                tôi. <br />
                Chúng tôi sẽ liên hệ với bạn trong thời gian sớm nhất để tư vấn.
                <br />
                Trân trọng cảm ơn !
              </p>
              <ul className={css.socials}>
                {/*<li>
                  <a href="#" target="_blank">
                    <img src="/images/commons/facebook.svg" alt="icon" />
                  </a>
                </li>
                 <li>
                  <a href="#" target="_blank">
                    <img src="/images/commons/twitter.svg" alt="icon" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <img src="/images/commons/instagram.svg" alt="icon" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <img src="/images/commons/linkedin.svg" alt="icon" />
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className={css.copyright}>
          <div className={css.text}>
            Copyright {new Date().getFullYear()},admin@yservice.net
          </div>
          {/* <div className={css.menu}>
            {menus?.map((menu) => (
              <Link to={menu.link}>{menu.label}</Link>
            ))}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Footer;
