import React, { useState, useEffect, useCallback } from "react";
import { Grid, Button, Box, IconButton, Text } from "@material-ui/core";

import { Delete } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";

// data
// import Filter from "./Filter";
import DataTable from "../../components/Table/Table";
import { depositList, depositCreate, depositCancel } from "../../apis/deposit";
import {
  numberWithCommas,
  convertTimestampToDateTime,
} from "../../helpers/utils";
import DialogCreate from "./DialogCreate";
import DialogHuongDan from "./DialogHuongDan";

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
}));
const defaultParams = {
  page: 1,
  limit: 100,
  search: "",
};

export default function Deposit() {
  const classes = useStyles();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [fullLoading, setFullLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  // const mounted = React.useRef(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openGuideDialog, setOpenGuideDialog] = useState(false);
  const [guideAmount, setGuideAmount] = useState(0);
  const [guideContent, setGuideContent] = useState(false);

  const [filter, setFilter] = useState(defaultParams);
  const [total, setTotal] = useState(0);
  const location = useLocation();
  const history = useHistory();

  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = queryString.parse(location.search);
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history],
  );

  async function listData() {
    const list = await depositList(filter);
    setList(list?.data);
    setTotal(list?.total);
  }
  useEffect(() => {
    listData();
    const timer1 = setInterval(async () => {
      await listData();
    }, 5000);

    return () => {
      clearInterval(timer1);
    };
  }, [filter]);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  const handleCreate = async (data) => {
    // setFullLoading(true);
    await depositCreate(data);

    await listData();
    // setFullLoading(false);
    setOpenAddDialog(false);
  };

  const handleColor = (status) => {
    if (
      status === "RUNNING" ||
      status === "CONNECTED" ||
      status === "FINISHED"
    ) {
      return "#33CC66";
    }

    if (
      status === "CANCELED" ||
      status === "DISCONNECT" ||
      status === "LIMITED" ||
      status === "FAILED"
    ) {
      return "#FF6666";
    }
    if (status === "DONT_USE") {
      return "#BBBBBB";
    }

    if (
      status === "CHANGE_IP" ||
      status === "START" ||
      status === "PROCESSING"
    ) {
      return "#FFCC33";
    }
    return "white";
    // return status == "LIVE" ? 'green' : status == "DEAD" ? 'red' : 'grey'
  };
  const columns = [
    {
      field: "stt",
      headerName: "STT",
      editable: false,
      flex: 0.1,
    },
    {
      field: "created_at",
      headerName: "Thời gian tạo",
      flex: 0.1,
      editable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: "normal" }}>
            <div style={{ lineHeight: "normal !important" }}>
              {convertTimestampToDateTime(params?.row?.created_at)}
            </div>
          </div>
        );
      },
    },
    {
      field: "amount",
      headerName: "Số tiền",
      flex: 0.2,
      editable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: "normal" }}>
            <div style={{ lineHeight: "normal !important" }}>
              {" "}
              {numberWithCommas(params?.row?.amount)} ({params?.row?.unit})
            </div>
          </div>
        );
      },
    },
    {
      field: "content",
      headerName: "nội dung chuyển khoản",
      flex: 0.2,
      editable: false,
      renderCell: (params) => {
        return (
          <div
            style={{
              lineHeight: "normal",
            }}
          >
            <div
              style={{
                lineHeight: "normal !important",
              }}
            >
              <strong>{params?.row?.content}</strong>
            </div>
          </div>
        );
      },
    },
    {
      field: "usage_status",
      headerName: "Trạng thái",
      flex: 0.1,
      editable: false,
      renderCell: (params) => {
        return (
          <div
            style={{
              lineHeight: "normal",
            }}
          >
            <div
              style={{
                lineHeight: "normal !important",
                backgroundColor: handleColor(params?.row?.status),
              }}
            >
              {params?.row?.status}
            </div>
          </div>
        );
      },
    },
    {
      field: "",
      headerName: "",
      type: "number",
      flex: 0.2,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.status === "PROCESSING" ? (
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  style={{
                    lineHeight: "normal !important",
                    margin: 10,
                  }}
                  backgroundColor="primary"
                  onClick={() => {
                    setGuideAmount(params?.row?.amount);
                    setGuideContent(params?.row?.content);
                    setOpenGuideDialog(true);
                  }}
                >
                  Hướng dẫn nạp tiền
                </Button>
                <IconButton>
                  <Delete
                    onClick={async () => {
                      await depositCancel(params?.row?.uid);
                      await listData();
                    }}
                  />
                </IconButton>
              </div>
            ) : (
              ""
            )}
          </>
        );
      },
    },
  ];
  return (
    <>
      {/* <FullLoading open={fullLoading} /> */}
      <PageTitle title="Lịch sử nạp tiền" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignContent="center"
            alignItems="center"
          >
            <Button
              onClick={() => setOpenAddDialog(true)}
              style={{ height: 48, width: 120, marginRight: 10 }}
              variant="contained"
              color="primary"
            >
              Nạp tiền
            </Button>
          </Box>
          <div style={{ marginLeft: 5, marginTop: 20, color: "red" }}>
            * Chú ý: Cần điền đúng <strong>SỐ TIỀN</strong> và{" "}
            <strong>NỘI DUNG CHUYỂN KHOẢN</strong> của giao dịch, điền sai sẽ
            mất thời gian 1 ngày để đối chiếu và xử lý
          </div>
        </Grid>
        <Grid item xs={12}>
          <Widget
            title=""
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
          >
            <DataTable
              checkboxSelection={false}
              rowHeight={90}
              columns={columns}
              data={list?.map((item, i) => ({
                ...item,
                id: item.uid,
                stt: i + 1 + (filter?.page - 1) * filter?.limit,
              }))}
              loading={loading}
              filter={filter}
              setFilter={setFilterToParams}
              setSelectedRow={setSelectedRow}
              total={total}
            />
          </Widget>
        </Grid>
      </Grid>

      {openGuideDialog && (
        <DialogHuongDan
          open={openGuideDialog}
          onClose={() => {
            setOpenGuideDialog(false);
          }}
          aria-labelledby="draggable-dialog-title"
          fullWidth
          maxWidth="lg"
          amount={guideAmount}
          content={guideContent}
        />
      )}
      {openAddDialog && (
        <DialogCreate
          open={openAddDialog}
          onClose={() => {
            setOpenAddDialog(false);
          }}
          aria-labelledby="draggable-dialog-title"
          fullWidth
          maxWidth="lg"
          onSubmitForm={async (data) => {
            // console.log(data);
            await handleCreate(data);
            await listData();
            setOpenAddDialog(false);
          }}
        />
      )}
    </>
  );
}
