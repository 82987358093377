import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import TextField from '@mui/material/TextField';

import { makeStyles } from "@material-ui/styles";
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";
import moment from "moment";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";

// data
// import Filter from "./Filter";
import DataTable from "../../components/Table/Table";
import DialogConfirm from "../../components/DialogConfirm/DialogConfirm";
import { orderList, orderCreate, orderCancel } from "../../apis/orders";
import { secondsToHms } from "../../helpers/utils";

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))
const defaultParams = {
  page: 1, limit: 100, search: ''
}
let timer
export default function ViewOrder() {
  const classes = useStyles();
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(true);
  // const [fullLoading, setFullLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  // const mounted = React.useRef(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [pushType, setPushType] = useState("");
  const [inputText, setInputText] = useState("")


  const [filter, setFilter] = useState(defaultParams);
  const [fileInput, setFileInput] = useState(undefined);
  const [total, setTotal] = useState(0);
  const location = useLocation();
  const history = useHistory()

  // set filter tu query parasm for url
  const updateQueryParams = useCallback(() => {
    if (location.search) {
      const filterParams = (queryString.parse(
        location.search
      ))
      setFilter({
        ...filterParams,
        page: parseInt(`${filterParams.page}`, 10),
        limit: parseInt(`${filterParams.limit}`, 10),
      });
    } else {
      history.replace({ search: queryString.stringify(defaultParams) });
    }
  }, [history, location.search]);

  // update filter with search and paging
  const setFilterToParams = React.useCallback(
    (values) => {
      history.replace({
        search: queryString.stringify({
          ...values,
        }),
      });
    },
    [history]
  );

  useEffect(() => {
    async function listData() {
      const list = await orderList(filter)
      setOrders(list?.data)
      setTotal(list?.total)
    }
    listData()

    const timer1 = setInterval(
      async () => {
        const list = await orderList(filter)
        setOrders(list?.data)
        setTotal(list?.total)
      }, 5000);

    return () => {
      clearInterval(timer1);
    }
  }, [filter]);

  useEffect(() => {
    updateQueryParams();
  }, [updateQueryParams]);

  const handleCancel = async () => {
    // setFullLoading(true);
    await orderCancel(selectedRow)
    const list = await orderList(filter)
    setOrders(list?.data)
    setTotal(list?.total)
    // setFullLoading(false);
    setOpenConfirm(false);
  }

  const handleCreate = async (data) => {
    await orderCreate(data)
    const list = await orderList(filter)
    setOrders(list?.data)
    setTotal(list?.total)
    setOpenAddDialog(false)
  }

  const handleStatusColor = (status) => {
    if (status === "FINISHED") {
      return 'green'
    }
    if (status === "DEAD" || status === "FAILED") {
      return "red"
    }
    if (status === "PROCESSING") {
      return "orange"
    }

    return "grey"
    // return status == "LIVE" ? 'green' : status == "DEAD" ? 'red' : 'grey'
  }

  const handlePushTypeColor = (pushType) => {
    if (pushType === "PUSH_VIEWS") {
      return '#99CC33'
    }

    return "#CC99FF"
    // return status == "LIVE" ? 'green' : status == "DEAD" ? 'red' : 'grey'
  }

  const columns = [
    {
      field: 'stt', headerName: 'STT', editable: false, flex: 0.01,
    },
    {
      field: '', headerName: 'Thumb', width: 150, editable: false, sortable: false,
      renderCell: (params) => <div style={{ cursor: 'pointer', display: 'flex', overflow: 'hidden', }}>
        <img src={params?.row?.thumb} alt=""
          onError={(ele) => { ele.currentTarget.src = '/images/default.jpeg'; }}
        />
      </div>
    },
    {
      field: 'yt_id', headerName: 'video',
      flex: 0.4,
      editable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: 'normal', }}>
            <a href={`https://www.youtube.com/watch?v=${params?.row?.yt_id}`} target="_blank"><strong>{params?.row?.yt_id}</strong></a>
            <div><strong>{params?.row?.current_view}</strong> views</div>
            <div style={{ lineHeight: 'normal !important' }}>{params?.row?.yt_title}</div>
            <div style={{ lineHeight: 'normal !important' }}>{moment.unix(params.row.created_at).format('DD-MM-YYYY HH:MM A')}</div>
          </div>
        )
      }
    },
    {
      field: 'yt_channel_id', headerName: 'channel',
      flex: 0.2,
      editable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: 'normal', }}>
            <a href={`https://www.youtube.com/channel/${params?.row?.yt_channel_id}`} target="_blank"><strong>{params?.row?.yt_channel_id}</strong></a>
            <div style={{ lineHeight: 'normal !important' }}>{params?.row?.yt_channel_title}</div>
            <div style={{ lineHeight: 'normal !important' }}></div>
          </div>
        )
      }
    },
    {
      field: 'duration', headerName: 'duration',
      width: 90,
      editable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: 'normal' }}>
            <div style={{ lineHeight: 'normal !important' }}> {secondsToHms(params?.row?.duration)} </div>
          </div>
        )
      }
    },
    {
      field: 'current_view', headerName: 'View',
      width: 90,
      editable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: 'normal', }}>
            <div style={{ lineHeight: 'normal !important' }}>
              <div><strong>{params?.row?.current_view - params?.row?.start_view}</strong>/{params?.row?.view_order}</div>
              <div style={{ lineHeight: 'normal !important' }}>
                (<strong>{(params?.row?.current_view - params?.row?.start_view) * 100 / params?.row?.view_order}%</strong>)
              </div>

            </div>
          </div>
        )
      }
    },
    {
      field: 'status', headerName: 'status', width: 120,
      flex: 0.1,
      editable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: 'normal' }}>
            <div style={{ lineHeight: 'normal !important' }}><strong style={{ backgroundColor: handleStatusColor(params?.row?.status), color: '#fff', padding: 3 }}>{params?.row?.status}</strong></div>
          </div>
        )
      }
    },
    {
      field: 'type', headerName: 'type', width: 120,
      flex: 0.1,
      editable: false,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: 'normal' }}>
            <div style={{ lineHeight: 'normal !important' }}><strong style={{ backgroundColor: handlePushTypeColor(params?.row?.push_type), color: '#fff', padding: 3 }}>{params?.row?.push_type}</strong></div>
          </div>
        )
      }
    },
  ];
  return (
    <>
      {/* <FullLoading open={fullLoading} /> */}
      <PageTitle title="View Orders" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="flex-end" alignContent="center" alignItems="center">
            <Button onClick={() => { setPushType("PUSH_HOURS"); setOpenAddDialog(true) }} style={{ height: 48, width: 120, marginRight: 10 }} variant="contained" color="primary">Bơm Giờ</Button>
            <Button onClick={() => { setPushType("PUSH_VIEWS"); setOpenAddDialog(true) }} style={{ height: 48, width: 120, marginRight: 10 }} variant="contained" color="primary">Bơm View</Button>
            <Button onClick={() => setOpenConfirm(true)} style={{ height: 48, width: 120, marginRight: 10 }} variant="contained" color="secondary">Cancel</Button>
            <Button onClick={() => { history.push('/app/old-view-orders') }} style={{ height: 48, width: 120, marginRight: 10 }} variant="contained" color="primary">Old Orders</Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Widget title="View Orders" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
            <DataTable
              checkboxSelection={true}
              rowHeight={90}
              columns={columns}
              data={orders?.map((item, i) => ({ ...item, id: item.uid, stt: i + 1 + (filter?.page - 1) * filter?.limit }))}
              loading={loading}
              filter={filter}
              setFilter={setFilterToParams}
              setSelectedRow={setSelectedRow}
              total={total}
            />
          </Widget>
        </Grid>
      </Grid>

      <Dialog
        open={openAddDialog}
        onClose={() => { setOpenAddDialog(false) }}
        aria-labelledby="draggable-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {/* {`Add google api keys`} */}
        </DialogTitle>
        {/* <DialogContent> 
          <div style={{ float: 'right' }}>
            {!channel?.length ? <AddBoxIcon onClick={() => { }} style={{ cursor: 'pointer' }} color="secondary" /> : <></>}
          </div>
        </DialogContent> */}
        <TextField
          style={{ margin: 10 }}
          id="outlined-multiline-static"
          label="Add short links"
          multiline
          rows={20}
          placeholder="1 line for 1 key"
          alignContent="10"
          onChange={(event) => {
            setInputText(event.target.value)
          }}
        />
        <DialogActions>
          <Button autoFocus onClick={() => { setOpenAddDialog(false) }} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained" color="primary" disabled={inputText.length > 0 ? false : true}
            onClick={async () => {
              var lines = inputText.split("\n")
              await handleCreate({
                push_type: pushType,
                data: lines?.map(pr => pr?.trim()),
              });
            }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <DialogConfirm open={openConfirm} onCancel={() => setOpenConfirm(false)} message="Are you want to cancel?" onSubmit={() => { handleCancel() }} />
    </>
  );
}
