import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Grid,
} from "@material-ui/core";
import { numberWithCommas } from "../../helpers/utils";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export default function DialogHuongDan({ open, onClose, amount, content }) {
  return (
    <div>
      <form>
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="draggable-dialog-title"
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Hướng dẫn Nạp tiền
          </DialogTitle>

          <div style={{ padding: 5 }}>
            <strong>Bước 1: Mở ứng dụng và đăng nhập mobile banking</strong>
          </div>

          <div style={{ padding: 5 }}>
            <strong>Bước 2: Chuyển tiền vào tài khoản:</strong>

            <div style={{ marginLeft: 25 }}>
              <div style={{ margin: 5 }}>
                <strong>Ngân hàng:</strong> Vietcombank
              </div>
              <div style={{ margin: 5 }}>
                <strong>Tên người nhận:</strong> Bùi Thị Hải Yến
              </div>
              <div style={{ margin: 5 }}>
                <strong>Số tài khoản:</strong> 1029558387
              </div>
              <div style={{ margin: 5 }}>
                <strong>Số tiền:</strong> {numberWithCommas(amount)} vnđ
              </div>
              <div style={{ margin: 5 }}>
                <strong>Nôi dung:</strong> {content}
              </div>
              <div style={{ marginLeft: 5, color: "red", fontSize: 15 }}>
                <strong>* Chú ý</strong>: Cần điền đúng <strong>SỐ TIỀN</strong>{" "}
                và <strong>NỘI DUNG CHUYỂN KHOẢN</strong> của giao dịch, điền
                sai sẽ mất thời gian 1 ngày để đối chiếu và xử lý.
              </div>

              <img
                src={`https://img.vietqr.io/image/Vietcombank-1029558387-compact2.jpg?amount=${amount}&addInfo=${content}&accountName=Bui%20Thi%20Hai%20Yen`}
                alt="icon"
              />
            </div>
          </div>

          <div style={{ padding: 5 }}>
            <strong>Bước 3: Chờ xác nhận từ hệ thống</strong>

            <div style={{ marginLeft: 25 }}>
              <div>Thường sẽ nhanh vì xử lý tự động</div>
              <div>
                Trong trường hợp điền sai thông tin thì cần đối chiếu với xử lý
              </div>
              <div>Liên hệ telegram: @kapuchino_1234</div>
            </div>
          </div>
          <Grid container spacing={4}>
            <Grid item xs={12}></Grid>
          </Grid>
          <DialogActions>
            <Button autoFocus onClick={onClose} color="primary">
              Đã đọc
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}
