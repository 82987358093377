import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControlLabel,
  Checkbox,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
} from "@material-ui/core";
import { numberWithCommas } from "../../helpers/utils";
import { counterGet } from "../../apis/counter";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export default function DialogCreate({ open, onClose, onSubmitForm }) {
  const classes = useStyles();
  const [service, setService] = React.useState("");
  const [amount, setAmount] = React.useState(0);
  const [userProxy, setUserProxy] = React.useState("");
  const [passProxy, setPassProxy] = React.useState("");
  const [isRandom, setIsRandom] = React.useState(true);
  const [changeIpDuration, setChangeIpDuration] = React.useState(0);

  const [errAmount, setErrAmount] = React.useState("");
  const [errService, setErrService] = React.useState("");
  const [cCounter, setCCounter] = useState({});

  useEffect(() => {
    async function fetchData() {
      const counter = await counterGet();
      setCCounter(counter);
    }
    fetchData();
  }, []);

  console.log(cCounter);

  const handleSubmit = () => {
    const data = {
      service: service,
      amount: amount,

      user_proxy: userProxy,
      pass_proxy: passProxy,
      is_random: isRandom,
      change_ip_duration: changeIpDuration,
    };

    if (service === "" || amount === 0) {
      if (amount === 0) {
        setErrAmount("Cần nhập amount");
      }
      if (service === "") {
        setErrService("Cần nhập service");
      }
    } else {
      onSubmitForm(data);
    }
  };

  return (
    <div>
      <form>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={open}
          onClose={onClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Tạo proxy</DialogTitle>
          <DialogContent>
            {cCounter?.proxy_viet_counter >= 3 ? (
              ""
            ) : (
              <div style={{ color: "red" }}>
                Bạn được tặng gấp đôi số proxy trong 3 lần đặt đầu tiên. Hiiện
                tại bạn đã đặt {cCounter?.proxy_viet_counter} lần
              </div>
            )}
            <FormControl className={classes.formControl}>
              <InputLabel
                shrink
                id="demo-simple-select-placeholder-label-label"
                error={errService}
              >
                Chọn loại proxy
              </InputLabel>
              <Select
                value={service}
                onChange={(event) => {
                  setService(event.target.value);
                  setErrService("");
                }}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem key="">
                  <em>Chọn loại Proxy</em>
                </MenuItem>
                {[
                  {
                    name: "1 ngày",
                    key: "PROXY_VIET_1D",
                    price: 16000,
                  },
                  {
                    name: "3 ngày",
                    key: "PROXY_VIET_3D",
                    price: 45000,
                  },
                  {
                    name: "7 ngày",
                    key: "PROXY_VIET_7D",
                    price: 90000,
                  },
                  {
                    name: "14 ngày",
                    key: "PROXY_VIET_14D",
                    price: 170000,
                  },
                  {
                    name: "30 ngày",
                    key: "PROXY_VIET_30D",
                    price: 350000,
                  },
                ]?.map((pk) => (
                  <MenuItem key={pk.key} value={pk.key}>
                    {pk.name}({numberWithCommas(pk.price)}đ)
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              id="amount"
              name="amount"
              label="Số proxy"
              type="number"
              value={amount}
              error={errAmount}
              onChange={(event) => {
                setAmount(parseInt(event.target.value));
                setErrAmount("");
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isRandom}
                  onChange={() => {
                    setIsRandom(!isRandom);
                  }}
                />
              }
              label="Random user pass"
            />
            <TextField
              fullWidth
              disabled={isRandom}
              id="userProxy"
              name="userProxy"
              label="userProxy"
              value={userProxy}
              onChange={(event) => setUserProxy(event.target.value)}
            />
            <TextField
              fullWidth
              disabled={isRandom}
              id="passProxy"
              name="passProxy"
              label="passProxy"
              value={passProxy}
              onChange={(event) => setPassProxy(event.target.value)}
            />
            <FormControl className={classes.formControl}>
              <InputLabel
                shrink
                id="demo-simple-select-placeholder-label-label"
              >
                thời gian đổi ip
              </InputLabel>
              <Select
                value={changeIpDuration}
                onChange={(event) => {
                  setChangeIpDuration(event.target.value);
                }}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={0}>
                  <em>30 phút</em>
                </MenuItem>
                {[
                  { name: "3 phút", value: 180 },
                  { name: "5 phút", value: 300 },
                  { name: "7 phút", value: 420 },
                  { name: "10 phút", value: 600 },
                  { name: "15 phút", value: 900 },
                  { name: "20 phút", value: 1200 },
                  { name: "23 phút", value: 1380 },
                  { name: "25 phút", value: 1500 },
                  { name: "27 phút", value: 1620 },
                  { name: "30 phút", value: 1800 },
                ]?.map((pk) => (
                  <MenuItem key={pk.name} value={pk.value}>
                    {pk.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>

          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button
              disabled={!amount || !service}
              onClick={handleSubmit}
              type="submit"
              variant="contained"
              color="primary"
            >
              Tạo proxy
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}
