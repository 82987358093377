const ImageBox = () => {
  return (
    <div className='imageBoxWrap'>
      <div className='container imageBoxContainer'>
        <div className='imageBoxLeft imageBoxItem'>
          <h3>Bạn sẽ kiếm tiền như thế nào</h3>
          <p>Khi bạn (Youtuber) trở thành Youtube Partner, họ sẽ đặt quảng cáo vào video của bạn. Quảng cáo có thể xuất hiện trong video hoặc cột bên phải trang xem video. Nếu ai đó bấm vào quảng cáo, bạn được chia % CPC (Cost Per Click). Đơn giá click thay đổi tùy theo từng quảng cáo.</p>
        </div>
        <div className='imageBoxRight imageBoxItem'>
          <img src='/images/about/about3.jpg' alt='About' />
        </div>
      </div>
      <div className='container imageBoxContainer imageLeft'>
        <div className='imageBoxRight imageBoxItem'>
          <img src='/images/about/about4.jpg' alt='About' />
        </div>
        <div className='imageBoxLeft imageBoxItem'>
          <h3>Lợi nhuận trên Youtube</h3>
          <p>Lợi nhuận chia sẻ lên đến tối đa 40%. Với gói đầu tư cao nhất đem đến lợi nhuận chia sẻ tối đa lên đến 40% (lợi nhuận). Ví dụ lợi nhuận sau chi phí là 1.000.000.000 vnd, đối tác sẽ nhận được 400.000.000.</p>
        </div>
      </div>
    </div>
  )
}

export default ImageBox
